import React, { useState } from 'react';
import { Button, notification } from 'antd';

import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';

import { ToastActions } from 'ias-lib';
import ResetPasswordRequestForm from 'shared/forms/ResetPasswordRequestForm';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';

import './ResetPassword.scss';
  
export default function ResetPasswordRequest() {
    const [fields, setFields] = useState([] as any[]);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayEndMessage, setDisplayEndMessage] = useState<boolean>(false);

    function handleSubmit (email: string) {
        setLoading(true);
        fetch(`${
                BaseUrlConstants.BASE_URL
            }reset-password-request`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                })
            },
        ).then((res: any) => {
            if(res.ok) {
                notification.success({
                    message: 'Mail de réinitialisation envoyé',
                });
                setDisplayEndMessage(true);
            } else {
                notification.error({
                    message: "Erreur lors de l'enregistrement",
                });
            }
            setLoading(false);
          }).catch((error: string) => {
            console.error(error);
            setLoading(false);
            ToastActions.createToastError(
                'Une erreur est survenue',
            )});
    };

    return (
        <div className="reset-password">
            <div className="__header">
                <div className="__logo">
                    <img
                        className="__icon"
                        src={logoIcon}
                        alt="Logo"
                    />
                    <img
                        className="__text"
                        src={logoText}
                        alt="La Cigale"
                    />
                </div>
                <div className="__catch-phrase">
                    <strong>Livraison de repas</strong> sur mesure à
                    domicile depuis <strong>1985</strong> sur{' '}
                    <strong>Nîmes</strong> et ses environs.
                </div>
                <a
                    href="https://repaslacigale.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        className="__more-info-btn"
                        ghost
                        shape="round"
                        size="large"
                    >
                        En savoir plus
                    </Button>
                </a>
            </div>
            <div className="__content">
                <h1>Modifier mon mot de passe</h1>
                <ResetPasswordRequestForm
                    onChange={setFields}
                    fields={fields}
                    onSubmit={handleSubmit}
                    loading={loading}
                />
                <Link to="/login">
                    <Button
                        type="link"
                        shape="round"
                        size="large"
                    >
                       <LeftOutlined /> Retour
                    </Button>
                </Link>
                {displayEndMessage && <b>Un mail vous a été envoyé, vous pouvez désormais quitter cette page.</b> }
            </div>
        </div>
    );
}