import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const GET_DATA = gql`
    {
        clientTypes {
            code
            label
        }
    }
`;

export default function useClientTypes() {
    const { data } = useQuery<{ clientTypes: Constant[] }>(GET_DATA);

    return data ? data.clientTypes : [];
}
