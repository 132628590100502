import moment from 'moment';

interface Options {
    ignoreFields?: string[];
    dateFields?: string[];
}

export default class ObjectService {
    static mapFormFieldsObject(
        obj: any,
        options: Options | null = null,
        prefix: string | null = null,
    ) {
        let map: any[] = [];
        const keys = Object.keys(obj).filter((k) => k !== '__typename');
        keys.forEach((baseName: any) => {
            if (
                options &&
                options.ignoreFields &&
                options.ignoreFields.includes(baseName)
            ) {
                return;
            }

            let name = baseName;

            let value = obj[name];
            if (prefix) {
                name = prefix + name;
            }
            if (baseName === 'date' || options?.dateFields?.includes(baseName)) {
                value = value ? moment(value) : null;
            }

            if (Array.isArray(value)) {
                map.push({
                    name: [name],
                    value: value.map((v: any) => (v.content ? v.content : v)),
                });
                value.forEach((v: any) => {
                    if (v.id) {
                        const pfx = `${name}.${v.id}.`;
                        map = map.concat(
                            this.mapFormFieldsObject(v, options, pfx),
                        );
                    }
                });
            } else if (value instanceof Object && !moment.isMoment(value)) {
                let pfx: string | null = null;
                if (name) {
                    pfx = `${name}.`;
                }
                map = map.concat(this.mapFormFieldsObject(value, options, pfx));
            } else {
                map.push({ name: [name], value });
            }
        });
        return map;
    }
}
