import React, { useState } from "react";
import { Tooltip, Button } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';

interface Props {
    value: string;
    onChange: (v: string) => void;
}

export default function MonthSelect({value, onChange}: Props) {
    const initialMonth = moment().format('MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(value || initialMonth);

    const previousMonth = () => {
        const currentMonthMoment = moment(currentMonth, 'MM-YYYY', true);

        if (currentMonthMoment.isValid()) {
            const newMonth = currentMonthMoment
                .subtract(1, 'months')
                .format('MM-YYYY');

            setCurrentMonth(newMonth);
            onChange(newMonth);
        } else {
            console.error('Invalid date format');
        }
    };

    const  nextMonth = () => {
        const currentMonthMoment = moment(currentMonth, 'MM-YYYY', true);

        if (currentMonthMoment.isValid()) {
            const newMonth = currentMonthMoment
                .add(1, 'months')
                .format('MM-YYYY');

            setCurrentMonth(newMonth);
            onChange(newMonth);
        } else {
            console.error('Invalid date format');
        }
    }


    return (
        <div className="week-select mb-4">
            <div style={{ display: 'inline-block', marginBottom: 32 }}>
                <b>Mois actuel:  { currentMonth.toString() }</b>
                <Tooltip title="Mois précédent">
                    <Button
                        shape="circle"
                        icon={<LeftOutlined />}
                        onClick={previousMonth}
                        style={{ float: 'left', marginRight: '60px' }}
                    />
                </Tooltip>
                <Tooltip title="Mois suivant">
                    <Button
                        shape="circle"
                        icon={<RightOutlined />}
                        onClick={nextMonth}
                        style={{ float: 'right', marginLeft: '60px' }}
                    />
                </Tooltip>
            </div>
        </div>
    )
}