// Apollo
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toIdValue } from "apollo-utilities";
import { ApolloLink, from } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { logout } from '../contexts/AuthContext';
import { setContext } from 'apollo-link-context';
import { createHttpLink  } from 'apollo-link-http';
//import {AuthContext, LoginStore} from "ias-auth-web";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import { getAuthToken } from "../contexts/AuthContext";

import Parameters from "../parameters";

let AUTH_TOKEN: string | null = null;

const cache: any = new InMemoryCache({
  cacheRedirects: {
    Query: {
      // See https://www.apollographql.com/docs/react/recipes/performance.html#cache-redirects
      // The idea is to link the "user" query with the "User" entity available in the cache.
      user: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: "User", id })),
    },
  },
});

const httpLink = createHttpLink({
  uri: BaseUrlConstants.BASE_URL + 'graphql/',
});

// @ts-ignore
const uploadLink = new createUploadLink({
    uri: BaseUrlConstants.BASE_URL + 'graphql/',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "X-Auth-Token": getAuthToken() ? getAuthToken().value : null,
    }
  }
});

const loggOutAfterware = onError(({ graphQLErrors }) => {
    if (! graphQLErrors) {
        return;
    }
    const error = graphQLErrors.filter((error: any) => error.unauthenticated)[0];

    if (error) {
         //AuthContext.logout();
    }
});

const client = new ApolloClient({
  link: loggOutAfterware.concat(authLink).concat(uploadLink).concat(httpLink),
  //link: from([loggOutAfterware, authLink, uploadLink, httpLink]),
  cache
});

function updateAuthToken(authToken: any) {
  AUTH_TOKEN = authToken;
}
//AuthContext.addListener(updateAuthToken);

export { client, updateAuthToken };
