import React, { useState, useEffect } from 'react';
import ArrayService from 'shared/services/ArrayService';
import { Button, Modal, Popover } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import SortListItem from 'components/SortListItem';
import './DeliveryDayList.scss';
import useDebounce from 'shared/hooks/useDebounce';
import ClientStatusBadge from 'shared/components/delivery/ClientStatusBadge';
import useDishTypes from 'shared/hooks/useDishTypes';
import { getUrlFormatedToken } from 'shared/contexts/AuthContext';
import moment from 'moment';
import StringService from 'shared/services/StringService';

interface DeliveryDayListProps {
    deliveries: Delivery[];
    meals: Meal[];
    onDeliveryChange: (deliveryId: Id, input: any) => void;
    onAssignCommand?: (commandId: Id, deliveryId: Id) => void;
    commands: Command[];
}

interface Delivery {
    id: Id;
    name: string;
    color: string;
    deliveryCommands?: {
        id: Id;
        sortOrder: number;
        command: {
            id: Id;
        };
        deliveryPictureUrl: string;
    }[];
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
}

interface Command {
    id: Id;
    client: Client;
}

interface Meal {
    id: Id;
    date: string;
    mealPeriod: string;
    menu: {
        id: Id;
        mealDefinition: {
            id: Id;
            name: string;
        };
    };
    dishes: {
        id: Id;
        dish: {
            id: Id;
            type: string;
            name: string;
        };
        quantity: number;
    }[];
    clientIdentity: {
        id: Id;
        firstname: string;
        lastname: string;
    };
    command: Command;
    status: string;
    deliveredAt: string | null;
    failureComment: string | null;
}

export default function DeliveryDayList({
    deliveries,
    meals,
    onDeliveryChange,
    onAssignCommand,
    commands,
}: DeliveryDayListProps) {
    const unassignedCommands = commands.filter(
        (command) =>
            deliveries.findIndex(
                (delivery) =>
                    delivery.deliveryCommands &&
                    delivery.deliveryCommands.findIndex(
                        (c) => c.command.id === command.id,
                    ) > -1,
            ) === -1,
    );

    return (
        <div className="delivery-day-list">
            {unassignedCommands.length > 0 && (
                <div className="__delivery-wrapper">
                    <div className="__title">Clients à assigner</div>
                    <div className="__commands">
                        {unassignedCommands.map((command) => (
                            <CommandCard
                                key={command.id}
                                deliveries={deliveries}
                                meals={meals.filter(
                                    (m) => m.command.id === command.id,
                                )}
                                command={command}
                                onCommandClick={onAssignCommand}
                            />
                        ))}
                    </div>
                </div>
            )}
            {deliveries.map((delivery) => (
                <DeliveryColumn
                    key={delivery.id}
                    delivery={delivery}
                    deliveries={deliveries}
                    meals={meals}
                    commands={commands}
                    onAssignCommand={onAssignCommand}
                    onDeliveryChange={onDeliveryChange}
                />
            ))}
        </div>
    );
}

interface DeliveryColumnProps {
    delivery: Delivery;
    deliveries: Delivery[];
    meals: Meal[];
    commands: Command[];
    onAssignCommand?: (commandId: Id, deliveryId: Id) => void;
    onDeliveryChange: (deliveryId: Id, input: any) => void;
}
function DeliveryColumn({
    delivery,
    deliveries,
    meals,
    commands,
    onAssignCommand,
    onDeliveryChange,
}: DeliveryColumnProps) {
    const [deliveryCommands, setDeliveryCommands] = useState(
        delivery.deliveryCommands,
    );

    useEffect(() => {
        setDeliveryCommands(delivery.deliveryCommands);
    }, [delivery.deliveryCommands]);

    const dOnDeliveryChange = useDebounce(onDeliveryChange, 1000);

    function handleMoveCommand(fromIndex: number, toIndex: number) {
        if (!deliveryCommands) {
            return;
        }
        let newCommands = deliveryCommands.sort((c1, c2) =>
            c1.sortOrder > c2.sortOrder ? 1 : -1,
        );
        const command = newCommands.find((c) => c.sortOrder === fromIndex);
        if (command) {
            newCommands.splice(fromIndex, 1);
            newCommands.splice(toIndex, 0, command);
            newCommands = newCommands
                .filter(ArrayService.filterUniqueBy((c) => c.command.id))
                .map((c, index) => ({
                    ...c,
                    sortOrder: index,
                }));

            setDeliveryCommands(newCommands);

            const newCommandsInput = newCommands.map((c) => ({
                sortOrder: c.sortOrder,
                command: c.command.id,
            }));
            dOnDeliveryChange(delivery.id, {
                deliveryCommands: newCommandsInput,
            });
        }
    }

    if (!deliveryCommands) {
        return null;
    }
    return (
        <div className="__delivery-wrapper">
            <div className="__title">{delivery.name}</div>
            <div className="__commands">
                {deliveryCommands
                    .sort(ArrayService.cmpBy('sortOrder'))
                    .map((c) => {
                        const command = commands.find(
                            (cmd) => cmd.id === c.command.id,
                        );
                        return (
                            command && (
                                <CommandCard
                                    key={c.id}
                                    selectedDelivery={{
                                        ...delivery,
                                        deliveryCommands,
                                    }}
                                    deliveries={deliveries}
                                    meals={meals.filter(
                                        (m) => m.command.id === command.id,
                                    )}
                                    command={command}
                                    onPositionChange={handleMoveCommand}
                                    onCommandClick={onAssignCommand}
                                />
                            )
                        );
                    })}
            </div>
        </div>
    );
}

interface CommandCardProps {
    selectedDelivery?: Delivery;
    deliveries: Delivery[];
    command: Command;
    meals: Meal[];
    onPositionChange?: (fromIndex: number, toIndex: number) => void;
    onCommandClick?: (commandId: Id, deliveryId: Id) => void;
}
function CommandCard({
    command,
    selectedDelivery,
    deliveries,
    meals,
    onPositionChange,
    onCommandClick,
}: CommandCardProps) {
    const groupedMeals = meals.reduce((acc, curr) => {
        const index = acc.findIndex((t) => t.menuId === curr.menu.id);

        if (index === -1) {
            acc.push({
                menuId: curr.menu.id,
                name: curr.menu.mealDefinition.name,
                count: 1,
            });
        } else {
            acc[index].count++;
        }

        return acc;
    }, [] as { menuId: Id; name: string; count: number }[]);

    const popoverContent = onCommandClick && (
        <>
            {deliveries.map((delivery) => (
                <Button
                    key={delivery.id}
                    style={{ background: delivery.color }}
                    icon={
                        selectedDelivery &&
                        delivery.id === selectedDelivery.id && <CheckOutlined />
                    }
                    onClick={() => onCommandClick(command.id, delivery.id)}
                >
                    {delivery.name}
                </Button>
            ))}
        </>
    );

    const popoverTrigger = (
        <div
            className="__action"
            style={{
                backgroundColor: selectedDelivery?.color || '#d0d0d0',
            }}
        ></div>
    );

    const content = (
        <>
            <div className="__details">
                <div className="__name">{`${
                    command.client.firstName
                } ${command.client.lastName.toUpperCase()}`}</div>
                <div className="__meals">
                    {groupedMeals.map((g) => (
                        <div key={g.menuId}>{`${g.count} ${g.name}`}</div>
                    ))}
                </div>
                <ClientStatusBadge commands={[command]} meals={meals} />
                {selectedDelivery && (
                    <DetailsModalButton
                        delivery={selectedDelivery}
                        command={command}
                        meals={meals}
                    />
                )}
            </div>
            {onCommandClick ? (
                <Popover
                    content={popoverContent}
                    trigger="click"
                    placement="right"
                    overlayClassName="delivery-day-list__command-card__action-menu"
                >
                    {popoverTrigger}
                </Popover>
            ) : (
                popoverTrigger
            )}
        </>
    );

    if (!selectedDelivery || !onPositionChange) {
        return (
            <div className="__command-card">
                <div className="__content">{content}</div>
            </div>
        );
    }

    const commandPosition =
        selectedDelivery?.deliveryCommands?.find(
            (c) => c.command.id === command.id,
        )?.sortOrder || 0;

    return (
        <SortListItem
            id={command.id}
            listId={selectedDelivery.id}
            index={commandPosition}
            onMove={onPositionChange}
            className="__command-card"
        >
            {content}
        </SortListItem>
    );
}

function getMealStatus(status: string) {
    const allStatus: { [status: string]: string } = {
        delivered: 'Livré',
        prepared: 'Préparé',
        pending: 'En attente',
        failed: 'Echoué',
    };
    return allStatus[status.toLowerCase()];
}

interface DetailsModalButtonProps {
    delivery: Delivery;
    command: Command;
    meals: Meal[];
}
function DetailsModalButton({
    delivery,
    command,
    meals,
}: DetailsModalButtonProps) {
    const [visible, setVisible] = useState(false);
    const dishTypes = useDishTypes();

    const failureComment = meals.find((m) => m.failureComment)?.failureComment;
    const deliveredAtStr = meals.find((m) => m.deliveredAt)?.deliveredAt;
    const deliveredAt = deliveredAtStr ? moment(deliveredAtStr) : null;

    const deliveryCommand = delivery.deliveryCommands?.find(
        (c) => c.command.id === command.id,
    );

    return (
        <>
            <Button
                type="link"
                className="__detail-btn"
                onClick={() => setVisible(true)}
            >
                Voir détails
            </Button>
            <Modal
                className="delivery-command-details-modal"
                title={`Commande de ${command.client.firstName} ${command.client.lastName}`}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className="__meals">
                    {meals.map((meal) => (
                        <div key={meal.id} className="__meal">
                            <div className="__header">
                                {`${meal.clientIdentity.firstname} ${meal.clientIdentity.lastname}`}
                                <div className={`__status --${meal.status}`}>
                                    {getMealStatus(meal.status)}
                                </div>
                            </div>
                            {dishTypes
                                .filter(
                                    (type) =>
                                        !!meal.dishes.find(
                                            (d) => d.dish.type === type.code,
                                        ),
                                )
                                .map((type) => (
                                    <div key={type.code} className="__dish">
                                        <div className="__label">
                                            {type.label}
                                        </div>
                                        <div>
                                            {
                                                meal.dishes.find(
                                                    (d) =>
                                                        d.dish.type ===
                                                        type.code,
                                                )?.dish.name
                                            }
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
                </div>
                {deliveredAt && (
                    <>
                        <div className="__delivered-at">{`Livré le ${StringService.capitalize(
                            deliveredAt.format('dddd'),
                        )} ${deliveredAt.format(
                            'DD',
                        )} ${StringService.capitalize(
                            deliveredAt.format('MMMM'),
                        )} ${deliveredAt.format('YYYY')} à ${deliveredAt.format(
                            'HH',
                        )}h${deliveredAt.format('mm')}`}</div>
                        {deliveryCommand?.deliveryPictureUrl && (
                            <img
                                className="__picture"
                                src={`${
                                    deliveryCommand.deliveryPictureUrl
                                }?X-AUTH-TOKEN=${getUrlFormatedToken()}`}
                                alt=""
                            />
                        )}
                    </>
                )}
                {failureComment && (
                    <div className="__failure-comment">{failureComment}</div>
                )}
            </Modal>
        </>
    );
}
