import React, { useState, useRef, useEffect } from 'react';
import {
    Map as LeafletMap,
    TileLayer,
    Polygon,
    Marker,
    Polyline,
} from 'react-leaflet';
import L from 'leaflet';
import { useMeasure } from 'react-use';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';
import 'leaflet/dist/leaflet.css';
import './DeliveryZoneMap.scss';
import useClientClusters from 'shared/components/delivery/useClientClusters';
import LeafletClientMarker from 'shared/components/delivery/LeafletClientMarker';
import HomeMarker, { HOME_LATLNG } from 'shared/components/delivery/HomeMarker';
import DeliveryPath from 'shared/components/delivery/DeliveryPath';

interface DeliveryZoneMapProps {
    zones: DeliveryZone[];
    onZoneChange: (zone: any) => void;
    onAssignClient?: (clientId: number, zoneId: Id | null) => void;
    selectedZoneId: string | null;
    clients?: Client[];
}

interface DeliveryZone {
    id: Id;
    name: string;
    points: Coordinates[];
    color: string;
    defaultClients?: {
        id: Id;
        client: {
            id: number;
        };
    }[];
}

interface Coordinates {
    lat: number;
    lng: number;
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
    address: {
        coordinates: Coordinates;
    };
}

const DEFAULT_LAT = HOME_LATLNG.lat;
const DEFAULT_LNG = HOME_LATLNG.lng;
const DEFAULT_ZOOM = 11;

let ignoreNextClick = false;

export default function DeliveryZoneMap({
    zones,
    onZoneChange,
    onAssignClient,
    selectedZoneId,
    clients,
}: DeliveryZoneMapProps) {
    const { containerRef, mapRef } = useUpdateSizeOnContainerChange();
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);

    function handleClick(e: any) {
        if (ignoreNextClick) {
            ignoreNextClick = false;
            return;
        }

        const zone = zones.find((z) => z.id === selectedZoneId);
        if (zone) {
            const newPoints = [...zone.points];
            newPoints.push(e.latlng);
            onZoneChange({
                id: zone.id,
                points: newPoints,
            });
        }
    }

    function deletePoint(latlng: Coordinates) {
        const zone = zones.find((z) => z.id === selectedZoneId);
        if (zone) {
            const iPoint = zone.points.findIndex(
                (p: Coordinates) =>
                    p.lat === latlng.lat && p.lng === latlng.lng,
            );
            if (iPoint > -1) {
                const newPoints = [...zone.points];
                newPoints.splice(iPoint, 1);
                onZoneChange({
                    id: zone.id,
                    points: newPoints,
                });
            }
        }
    }

    function handlePointMove(prevPoint: Coordinates, newPoint: Coordinates) {
        const zone = zones.find((z) => z.id === selectedZoneId);
        if (zone) {
            const iPoint = zone.points.findIndex(
                (p: Coordinates) =>
                    p.lat === prevPoint.lat && p.lng === prevPoint.lng,
            );
            if (iPoint > -1) {
                const newPoints = [...zone.points];
                newPoints[iPoint] = newPoint;
                onZoneChange({
                    id: zone.id,
                    points: newPoints,
                });
            }
        }
    }

    function addPoint(point: Coordinates, index: number) {
        const zone = zones.find((z) => z.id === selectedZoneId);
        if (zone) {
            const newPoints = [...zone.points];
            if (index >= newPoints.length) {
                newPoints.push(point);
            } else {
                newPoints.splice(index, 0, point);
            }
            onZoneChange({
                id: zone.id,
                points: newPoints,
            });
        }
    }

    const position = [DEFAULT_LAT, DEFAULT_LNG] as [number, number];
    return (
        <div className="delivery-map" ref={containerRef}>
            <LeafletMap
                zoom={zoom}
                center={position}
                ref={mapRef}
                onZoomend={(e: any) => {
                    setZoom(e.target.getZoom());
                }}
                maxZoom={18}
                minZoom={9}
                doubleClickZoom={false}
                onClick={handleClick}
            >
                <TileLayer
                    url={`${BaseUrlConstants.BASE_URL}map-tiles/{z}/{x}/{y}.png`}
                />
                {zones.map((zone) => (
                    <React.Fragment key={zone.id}>
                        <Polygon
                            color={zone.color}
                            positions={
                                zone.points.map((p) => [p.lat, p.lng]) as any
                            }
                        />
                        {zone.id === selectedZoneId && (
                            <>
                                {zone.points.map((point: any) => (
                                    <PointMaker
                                        position={point}
                                        onClick={() => {
                                            deletePoint(point);
                                        }}
                                        onMove={(newPoint: Coordinates) =>
                                            handlePointMove(point, newPoint)
                                        }
                                    />
                                ))}
                                {zone.points.map(
                                    (point: any, index: number) => {
                                        return (
                                            <Polyline
                                                key={index}
                                                positions={[
                                                    point,
                                                    index ===
                                                    zone.points.length - 1
                                                        ? zone.points[0]
                                                        : zone.points[
                                                              index + 1
                                                          ],
                                                ]}
                                                color="rgba(255,255,255,.05)"
                                                weight={10}
                                                onClick={(e: any) => {
                                                    ignoreNextClick = true;

                                                    const point1 = point;
                                                    const point2 =
                                                        index ===
                                                        zone.points.length - 1
                                                            ? zone.points[0]
                                                            : zone.points[
                                                                  index + 1
                                                              ];

                                                    const newPoint = {
                                                        lat:
                                                            (point1.lat +
                                                                point2.lat) /
                                                            2,
                                                        lng:
                                                            (point1.lng +
                                                                point2.lng) /
                                                            2,
                                                    };
                                                    addPoint(
                                                        newPoint,
                                                        index + 1,
                                                    );
                                                }}
                                            />
                                        );
                                    },
                                )}
                            </>
                        )}
                    </React.Fragment>
                ))}
                <HomeMarker />
                {clients && !selectedZoneId && (
                    <>
                        <ClientMarkers
                            clients={clients}
                            deliveryZones={zones}
                            zoom={zoom}
                            onAssignClient={onAssignClient}
                        />
                        <DeliveryPath deliveryZones={zones} clients={clients} />
                    </>
                )}
            </LeafletMap>
        </div>
    );
}

interface ClientMarkersProps {
    clients: Client[];
    deliveryZones: DeliveryZone[];
    zoom: number;
    onAssignClient?: (clientId: number, zoneId: Id | null) => void;
}
function ClientMarkers({
    clients,
    zoom,
    deliveryZones,
    onAssignClient,
}: ClientMarkersProps) {
    const clientClusters = useClientClusters(clients, zoom);
    return (
        <>
            {clientClusters.map((c) => (
                <LeafletClientMarker
                    key={c.key}
                    deliveryZones={
                        deliveryZones as (DeliveryZone & {
                            defaultClients: {
                                id: Id;
                                client: {
                                    id: number;
                                };
                            }[];
                        })[]
                    }
                    cluster={c}
                    onClientClick={onAssignClient}
                />
            ))}
        </>
    );
}

function PointMaker({ position, onClick, onMove }: any) {
    const refmarker = useRef(null);
    return (
        <Marker
            draggable={true}
            onDragend={(e: any) => {
                const marker = refmarker.current as any;
                if (marker) {
                    const latlng = marker.leafletElement.getLatLng();
                    onMove(latlng);
                }
            }}
            position={position}
            onClick={onClick}
            icon={L.divIcon({
                html: `<div class="__point-marker"></div>`,
            })}
            ref={refmarker}
        />
    );
}

function useUpdateSizeOnContainerChange() {
    const mapRef = useRef(null);
    const [ref, { width, height }] = useMeasure();
    useEffect(() => {
        if (mapRef.current) {
            // @ts-ignore
            mapRef.current.leafletElement.invalidateSize();
        }
    }, [width, height]);
    return {
        containerRef: ref as (instance: any) => void,
        mapRef,
    };
}
