import React, { useState, useEffect } from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Form, Input, Button } from 'antd';

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onLogin: (login: string, password: string) => void;
    loading: boolean;
}
export default function LoginForm({
    fields,
    onChange,
    onLogin,
    loading,
}: Props) {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();

    useEffect(() => {
        forceUpdate({});
    }, []);

    function handleSubmit(values: any) {
        if (onLogin) {
            const { login, password } = values;
            onLogin(login, password);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
        >
            <Form.Item
                name="login"
                rules={[
                    { required: true, message: 'Renseignez votre identifiant' },
                ]}
            >
                <Input
                    prefix={
                        <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="Identifiant"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: 'Renseignez votre mot de passe' },
                ]}
            >
                <Input
                    prefix={
                        <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Mot de passe"
                />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    shape="round"
                >
                    Connexion
                </Button>
            </Form.Item>
        </Form>
    );
}
