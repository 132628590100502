import React, { useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import ClientStatusBadge from 'shared/components/delivery/ClientStatusBadge';
import { Popover, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import './LeafletClientMarker.scss';

const DEFAULT_COLOR = '#aaaaaa';

interface Cluster {
    key: string;
    latitude: number;
    longitude: number;
    clients: Client[];
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
    address: {
        coordinates: {
            lat: number;
            lng: number;
        };
    };
}

interface Delivery {
    id: Id;
    name: string;
    color: string;
    deliveryCommands: {
        command: Command;
    }[];
}

interface DeliveryZone {
    id: Id;
    name: string;
    color: string;
    defaultClients: {
        id: Id;
        client: {
            id: number;
        };
    }[];
}

interface SanitazedZone {
    id: Id;
    name: string;
    color: string;
    clients: {
        id: number;
    }[];
}

interface Command {
    id: string;
    client: {
        id: number;
    };
}

interface Meal {
    command: {
        id: Id;
    };
    status: string;
}

interface LeafletClientMarkerProps {
    rounds?: Delivery[];
    deliveryZones?: DeliveryZone[];
    commands?: Command[];
    meals?: Meal[];
    cluster: Cluster;
    onClientClick?: (clientId: number, zoneId: Id | null) => void;
}
export default function LeafletClientMarker({
    rounds,
    deliveryZones,
    commands,
    meals,
    cluster,
    onClientClick,
}: LeafletClientMarkerProps) {
    const el = useMemo(() => document.createElement('div'), []);
    useEffect(() => {
        return () => el.remove();
    }, [el]);

    const position = [cluster.latitude, cluster.longitude] as [number, number];
    return (
        <>
            <Marker
                position={position}
                icon={L.divIcon({
                    html: el,
                })}
            ></Marker>
            {ReactDOM.createPortal(
                <ClientMarker
                    cluster={cluster}
                    rounds={rounds}
                    deliveryZones={deliveryZones}
                    commands={commands}
                    meals={meals}
                    onClientClick={onClientClick}
                />,
                el,
            )}
        </>
    );
}

function onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
}

function ClientMarker({
    cluster,
    rounds,
    deliveryZones,
    commands,
    meals,
    onClientClick,
}: LeafletClientMarkerProps) {
    const [popoverVisible, setPopoverVisible] = useState(false);

    // We convert the given zones versions to a standard common in the rest of the component.
    let sanitazedZones: SanitazedZone[] = [];
    if (rounds) {
        sanitazedZones = rounds.map((zone) => ({
            id: zone.id,
            name: zone.name,
            color: zone.color,
            clients: zone.deliveryCommands.map((c) => c.command.client),
        }));
    } else if (deliveryZones) {
        sanitazedZones = deliveryZones.map((zone) => ({
            id: zone.id,
            name: zone.name,
            color: zone.color,
            clients: zone.defaultClients.map((c) => c.client),
        }));
    }

    const selectedRounds: (SanitazedZone | null)[] = sanitazedZones.filter(
        (zone) =>
            zone.clients.findIndex(
                (c) => cluster.clients.findIndex((c1) => c1.id === c.id) > -1,
            ) > -1,
    );

    if (
        cluster.clients.findIndex(
            (c) =>
                sanitazedZones.findIndex(
                    (r) => r.clients.findIndex((cd) => cd.id === c.id) > -1,
                ) === -1,
        ) > -1
    ) {
        selectedRounds.push(null);
    }

    let colorStr = DEFAULT_COLOR;
    let colorArrow = colorStr;
    if (selectedRounds.length > 0) {
        const colors = selectedRounds
            .map((r) => (r ? r.color : DEFAULT_COLOR))
            .filter(onlyUnique);
        if (colors.length === 1) {
            colorStr = colors[0];
            colorArrow = colorStr;
        } else {
            colorStr = 'linear-gradient(45deg';
            colors.forEach((color: string, index: number) => {
                colorStr += `, ${color} ${
                    (100 / colors.length) * index
                }%, ${color} ${(100 / colors.length) * (index + 1)}%`;
            });
            colorStr += ')';
            colorArrow = colors[0];
        }
    }

    const clusterCommands = commands
        ? commands.filter(
              (c) =>
                  cluster.clients.findIndex((cl) => cl.id === c.client.id) > -1,
          )
        : [];
    const clusterMeals = meals
        ? meals.filter(
              (m) =>
                  clusterCommands.findIndex((c) => c.id === m.command.id) > -1,
          )
        : [];

    return (
        <div className="leaflet-client-marker">
            <div
                className={`__content ${popoverVisible ? '--open' : ''}`}
                style={{ background: colorStr }}
            >
                <div className="__counter">
                    {cluster.clients.length}
                    {
                        <ClientStatusBadge
                            commands={clusterCommands}
                            meals={clusterMeals}
                        />
                    }
                </div>
                <div className="__clients">
                    {cluster.clients.map((client) => {
                        const selectedZone = sanitazedZones.find(
                            (r) =>
                                r.clients.findIndex((c) => c.id === client.id) >
                                -1,
                        );
                        const popoverContent = (
                            <>
                                <Button
                                    style={{ background: '#aaaaaa' }}
                                    icon={!selectedZone && <CheckOutlined />}
                                    onClick={
                                        onClientClick
                                            ? () =>
                                                  onClientClick(client.id, null)
                                            : undefined
                                    }
                                >
                                    Aucune
                                </Button>
                                {sanitazedZones.map((zone) => (
                                    <Button
                                        key={zone.id}
                                        style={{ background: zone.color }}
                                        icon={
                                            selectedZone &&
                                            zone.id === selectedZone.id && (
                                                <CheckOutlined />
                                            )
                                        }
                                        onClick={
                                            onClientClick
                                                ? () =>
                                                      onClientClick(
                                                          client.id,
                                                          zone.id,
                                                      )
                                                : undefined
                                        }
                                    >
                                        {zone.name}
                                    </Button>
                                ))}
                            </>
                        );

                        const clientCommands = clusterCommands.filter(
                            (c) => c.client.id === client.id,
                        );
                        const clientMeals = clusterMeals.filter(
                            (m) =>
                                clientCommands.findIndex(
                                    (c) => c.id === m.command.id,
                                ) > -1,
                        );

                        const item = (
                            <div
                                key={client.id}
                                className={`__item ${
                                    onClientClick ? '--clickable' : ''
                                }`}
                                style={{
                                    background: selectedZone
                                        ? selectedZone.color
                                        : DEFAULT_COLOR,
                                }}
                            >
                                {`${client.firstName} ${client.lastName}`}
                                {
                                    <ClientStatusBadge
                                        commands={clientCommands}
                                        meals={clientMeals}
                                    />
                                }
                            </div>
                        );
                        if (!onClientClick) {
                            return item;
                        }
                        return (
                            <Popover
                                key={client.id}
                                content={popoverContent}
                                trigger="click"
                                placement="right"
                                onVisibleChange={setPopoverVisible}
                                overlayClassName="leaflet-client-marker__action-menu"
                            >
                                {item}
                            </Popover>
                        );
                    })}
                </div>
            </div>
            <div
                className="__arrow"
                style={{ borderTopColor: colorArrow }}
            ></div>
        </div>
    );
}
