export default class InputNumberService {
    static inputNumberValidator = (rule:any, value:any) => {
        const message = rule.message
        try {
            const object = Number(value)
            if (isNaN(object)) throw new Error(message)
            return Promise.resolve()
        } catch (err) {
            return Promise.reject(err)
        }
    }
}