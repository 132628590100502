import React from 'react';
import { Tag } from 'antd';

export const statusInvoiceLabelMap: { [statusInvoice: string]: string } = {
    enabled: 'Payé',
    disabled: 'Non Payé',
    imported: 'Importé',
    not_imported: 'Non importé',
    sent: 'Oui',
    not_sent: 'Non'
};

const statusInvoiceColorMap: { [statusInvoice: string]: string | undefined } = {
    enabled: 'green',
    disabled: 'red',
    imported: 'blue',
    not_imported: 'red',
    sent: 'green',
    not_sent: 'red'
};

export default function StatusInvoiceTag({
    statusInvoice
}: { statusInvoice: string }) {
    return <Tag color={statusInvoiceColorMap[statusInvoice]}>{statusInvoiceLabelMap[statusInvoice]}</Tag>;
}