import React from 'react';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import DeliveryService from 'shared/services/DeliveryService';
import './ClientStatusBadge.scss';

interface ClientStatusBadgeProps {
    commands: Command[];
    meals: Meal[];
}

interface Command {
    id: Id;
}

interface Meal {
    command: {
        id: Id;
    };
    status: string;
}

export default function ClientStatusBadge({
    commands,
    meals,
}: ClientStatusBadgeProps) {
    const progress = DeliveryService.getCommandsProgress(commands, meals);
    if (progress === 0) {
        return null;
    }
    if (progress >= 100) {

        if(meals.findIndex(m => m.status === 'failed') > -1) {
            return (
                <div className="client-status-badge --failed">
                    <StopOutlined />
                </div>
            );
        }
        return (
            <div className="client-status-badge --complete">
                <CheckOutlined />
            </div>
        );
    }
    return <div className="client-status-badge">{`${progress}%`}</div>;
}
