import { useContext } from 'react';

import { AuthContext } from 'shared/contexts/AuthContext';

export const ROLES = {
    MANAGER: 'manager',
    DELIVERY_MAN: 'delivery-man',
    COOK: 'cook',
};

export default function usePermissions() {
    const { user } = useContext(AuthContext);
    function isGranted(role: string) {
        return user?.roles.includes(role);
    }
    function isLoaded() {
        return user && user.roles;
    }
    return {
        isGranted,
        loading: !user?.roles,
    };
}
