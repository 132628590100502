// Client clusters allow to regroup neighboors clients

import { useMemo } from 'react';
import { HOME_LATLNG } from './HomeMarker';

interface Cluster {
    key: string;
    latitude: number;
    longitude: number;
    clients: Client[];
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
    address: {
        coordinates: {
            lat: number;
            lng: number;
        };
    };
}

const DEFAULT_LATLNG = HOME_LATLNG;

function getLat(address: any) {
    return address.coordinates && address.coordinates.lat
        ? address.coordinates.lat
        : DEFAULT_LATLNG.lat;
}

function getLng(address: any) {
    return address.coordinates && address.coordinates.lng
        ? address.coordinates.lng
        : DEFAULT_LATLNG.lng;
}

function distanceInMeters(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
) {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515 * 1.609344 * 1000;
        return dist;
    }
}

export default function useClientClusters(clients: Client[], zoom: number) {
    return useMemo(() => {
        const scale = Math.pow(2, zoom);
        const distThreshold = (2000 * 2048) / scale;

        const clientClusters: Cluster[] = [];
        const ignoredClients: number[] = [];
        clients.forEach((client) => {
            if (ignoredClients.indexOf(client.id) === -1) {
                ignoredClients.push(client.id);

                const commandLat = getLat(client.address);
                const commandLng = getLng(client.address);

                const clusterClients = clients.filter((c) => {
                    if (ignoredClients.indexOf(c.id) > -1) {
                        return false;
                    }
                    const cLat = getLat(c.address);
                    const cLng = getLng(c.address);

                    const dist = distanceInMeters(
                        cLat,
                        cLng,
                        commandLat,
                        commandLng,
                    );
                    if (dist < distThreshold) {
                        ignoredClients.push(c.id);
                        return true;
                    }
                    return false;
                });
                clusterClients.push(client);

                const latitude = clusterClients.reduce(
                    (prev: number, curr) =>
                        prev + (curr.address.coordinates || DEFAULT_LATLNG).lat,
                    0,
                );
                const longitude = clusterClients.reduce(
                    (prev: number, curr) =>
                        prev + (curr.address.coordinates || DEFAULT_LATLNG).lng,
                    0,
                );
                clientClusters.push({
                    key: clusterClients.map((c) => c.id).join('_'),
                    latitude: latitude / clusterClients.length,
                    longitude: longitude / clusterClients.length,
                    clients: clusterClients,
                });
            }
        });
        return clientClusters;
    }, [clients, zoom]);
}
