import React, { useState } from 'react';
import { Form, Button, Row, Col, Input } from 'antd';

interface CommandinvoiceSendFormProps {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSubmit: (values: any) => void;
    loading: boolean;
    refetch: () => void;
}

export default function CommandInvoiceSendForm({
    fields,
    onChange,
    onSubmit,
    loading,
    refetch,
}: CommandinvoiceSendFormProps) {

    const [, forceUpdate] = useState();
    const [form] = Form.useForm();

    return (
        <Form
            layout="vertical"
            form={form}
            fields={fields}
            onFieldsChange={(_, fields: any) => {
                onChange(fields);
            }}
            onFinish={onSubmit}
            hideRequiredMark
        >
            <Row gutter={16}>
                <Col span={16}>
                <Form.Item
                    name="nameInvoice"
                    label="Facture N° "
                >
                    <Input readOnly={true}/>
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={16}>
                <Form.Item
                        name="client.email"
                        label="Adresse E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: "Cet email n'est pas valide",
                            },
                        ]}
                >
                    <Input />
                </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={16}>
                <Form.Item
                    name="client.emailBis"
                    label="Adresse E-mail bis"
                    rules={[
                        {
                            type: 'email',
                            message: "Cet email n'est pas valide",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
                <Col offset={6} span={12}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            shape="round"
                            style={{
                                width: '95%',
                                padding: '5px',
                                height: '40px',
                                maxWidth: '300px',
                            }}
                        >
                            Enregistrer
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}


