import React, { useState, useCallback } from 'react';
import DeliveryZoneMap from './DeliveryZoneMap';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import './SeniorDeliveryZone.scss';
import { Tabs, Button, notification } from 'antd';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import DeliveryZoneList from './DeliveryZoneList';
import DeliveryZoneItem, { DeliveryZoneInput } from './DeliveryZoneItem';
import AddZoneButton from './AddDeliveryZoneButton';
import ClientsAlert from 'shared/components/delivery/ClientsAlert';

const GET_DATA = gql`
    {
        deliveryZones(clientType: "senior") {
            id
            name
            color
            points {
                lat
                lng
            }
            defaultDeliveryMan {
                id
            }
            defaultClients {
                id
                client {
                    id
                }
                sortOrder
            }
        }

        clients(clientType: "senior", enabled: true) {
            id
            firstName
            lastName
            address {
                coordinates {
                    lat
                    lng
                }
            }
        }

        employees(roles: ["delivery-man"]) {
            id
            firstName
            lastName
        }
    }
`;

interface DeliveryZone {
    id: Id;
    name: string;
    color: string;
    points: {
        lat: number;
        lng: number;
    }[];
    defaultDeliveryMan: {
        id: number;
    } | null;
    defaultClients?: {
        id: Id;
        client: {
            id: number;
        };
        sortOrder: number;
    }[];
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
    address: {
        coordinates: {
            lat: number;
            lng: number;
        };
    };
}

interface Employee {
    id: number;
    firstName: string;
    lastName: string;
}

interface Data {
    deliveryZones: DeliveryZone[];
    employees: Employee[];
    clients: Client[];
}

const EDIT_ZONE = gql`
    mutation editDeliveryZone($id: UUID!, $input: DeliveryZoneInput!) {
        editDeliveryZone(id: $id, input: $input) {
            id
            name
            color
            points {
                lat
                lng
            }
            defaultDeliveryMan {
                id
                firstName
                lastName
            }
            defaultClients {
                id
                client {
                    id
                }
                sortOrder
            }
        }
    }
`;

const DELETE_ZONE = gql`
    mutation deleteDeliveryZone($id: UUID!) {
        deleteDeliveryZone(id: $id)
    }
`;

const ASSIGN_CLIENT = gql`
    mutation assignClientToDeliveryZone($client: Int!, $deliveryZone: UUID) {
        assignClientToDeliveryZone(
            client: $client
            deliveryZone: $deliveryZone
        ) {
            id
            name
            color
            points {
                lat
                lng
            }
            defaultDeliveryMan {
                id
            }
            defaultClients {
                id
                client {
                    id
                }
                sortOrder
            }
        }
    }
`;

function handleMutationError(err: Error) {
    notification.error({
        message: "Erreur lors de l'enregistrement",
        description: err.message,
    });
}

export default function DeliveryZone() {
    const [selectedZoneId, setSelectedZoneId] = useState(null as string | null);
    const { loading, data, refetch } = useQuery<Data>(GET_DATA, {
        fetchPolicy: 'cache-and-network',
    });

    const [editZone] = useMutation(EDIT_ZONE, {
        onError: handleMutationError,
    });

    const [deleteZone] = useMutation(DELETE_ZONE, {
        onCompleted: () => {
            refetch();
        },
        onError: handleMutationError,
    });

    const [assignClientToDeliveryZone] = useMutation(ASSIGN_CLIENT, {
        onError: handleMutationError,
    });

    function handleZoneDelete(zoneId: string) {
        deleteZone({
            variables: {
                id: zoneId,
            },
        });
    }

    const handleZoneChange = useCallback(
        (zone: DeliveryZoneInput | undefined) => {
            if (!zone) {
                return;
            }

            const input = {
                name: zone.name,
                points: zone.points?.map((p) => ({
                    lat: p.lat.toFixed(4),
                    lng: p.lng.toFixed(4),
                })),
                defaultDeliveryMan: zone.defaultDeliveryMan,
                defaultClients: zone.defaultClients,
            };
            editZone({
                variables: {
                    id: zone.id,
                    input,
                },
            });
        },
        [editZone],
    );

    function handleAssignClient(clientId: number, zoneId: Id | null) {
        assignClientToDeliveryZone({
            variables: {
                client: clientId,
                deliveryZone: zoneId,
            },
        });
    }

    const deliveryZones = data ? data.deliveryZones : [];

    const selectedZone = deliveryZones.find((z) => z.id === selectedZoneId);

    const unassignedClients =
        data?.clients?.filter((client) => {
            return (
                deliveryZones.findIndex(
                    (zone) =>
                        zone.defaultClients &&
                        zone.defaultClients.findIndex(
                            (c) => c.client.id === client.id,
                        ) > -1,
                ) === -1
            );
        }) || [];

    const clientsWithoutCoordinates =
        data?.clients?.filter((client) => {
            return !client.address.coordinates;
        }) || [];

    return (
        <div className="senior-delivery-zone">
            <h2>Zones de livraison : Séniors</h2>
            <div className="__content">
                <div className="__zone-list">
                    <ClientsAlert unassignedClients={unassignedClients} />
                    <ClientsAlert
                        unassignedClients={clientsWithoutCoordinates}
                        message="sans coordonnées GPS"
                    />
                    <AddZoneButton
                        onCreate={refetch}
                        employees={data ? data.employees : []}
                        clientType="senior"
                    />
                    {/*
                    <AutoAssignClientsButton />
                    */}

                    {loading && <LoaderOverlay loading />}

                    {deliveryZones.map((zone) => (
                        <DeliveryZoneItem
                            key={zone.id}
                            zone={zone}
                            isSelected={zone.id === selectedZoneId}
                            /*
                            onSelect={() =>
                                setSelectedZoneId(
                                    zone.id === selectedZoneId ? null : zone.id,
                                )
                            }
                            */
                            onDelete={() => handleZoneDelete(zone.id)}
                            onChange={handleZoneChange}
                            employees={data ? data.employees : []}
                            clients={data?.clients}
                        />
                    ))}
                    {selectedZone && (
                        <Button
                            onClick={() => setSelectedZoneId(null)}
                            shape="round"
                            className="__deselect-zone-btn"
                        >
                            Terminer le tracé
                        </Button>
                    )}
                </div>
                <div className="__round-map">
                    <div
                        className={`__selected-zone-indicator ${
                            selectedZone ? '--selected-zone' : ''
                        }`}
                        style={
                            selectedZone
                                ? { backgroundColor: selectedZone.color }
                                : undefined
                        }
                    />
                    <Tabs defaultActiveKey="map">
                        <Tabs.TabPane tab="Carte" key="map">
                            <DeliveryZoneMap
                                zones={deliveryZones}
                                clients={data ? data.clients : []}
                                onZoneChange={handleZoneChange}
                                onAssignClient={handleAssignClient}
                                selectedZoneId={selectedZoneId}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Liste" key="list">
                            <DeliveryZoneList
                                zones={deliveryZones}
                                onZoneChange={handleZoneChange}
                                clients={data ? data.clients : []}
                                onAssignClient={handleAssignClient}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

/*
const AUTO_ASSIGN_CLIENTS_TO_DELIVERY_ZONES = gql`
    mutation autoAssignClientsToDeliveryZones {
        autoAssignClientsToDeliveryZones(clientType: "senior") {
            id
            defaultClients {
                id
                client {
                    id
                }
                sortOrder
            }
        }
    }
`;
function AutoAssignClientsButton() {
    const [autoAssignClientsToDeliveryZones, { loading }] = useMutation(
        AUTO_ASSIGN_CLIENTS_TO_DELIVERY_ZONES,
        {
            onError: handleMutationError,
        },
    );
    return (
        <Button
            className="__auto-assign-btn"
            shape="round"
            onClick={() => autoAssignClientsToDeliveryZones()}
            loading={loading}
        >
            Générer les zones de livraisons
        </Button>
    );
}
*/