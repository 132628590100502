import React, { useEffect, useState } from 'react';
import BaseUrlConstants from "shared/constants/BaseUrlConstants";
import {Button, notification} from "antd";
import {getAuthToken, getUrlFormatedToken} from "shared/contexts/AuthContext";
import LoaderOverlay from "shared/components/LoaderOverlay";


function Factures() {
    interface OuvertesState {
        [key: string]: boolean;
    }
    const [facturesData, setFacturesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ouvertes, setOuvertes] = useState<OuvertesState>({});

    const loadFactures = async () => {
        setIsLoading(true);
        const AUTH_TOKEN = getAuthToken().value;
        try {
            const response = await fetch(`${BaseUrlConstants.BASE_URL}api/invoices/factures/liste`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-AUTH-TOKEN': AUTH_TOKEN,
                }
            });
            const data = await response.json();
            if (response.status === 200) {
                setFacturesData(data);
            }
        } catch (error) {
            notification.error({ message: "Erreur lors du chargement des factures." });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadFactures();
    }, []);

    const toggleOuverture = (periode: any) => {
        setOuvertes((prevOuvertes: any) => ({
            ...prevOuvertes,
            [periode]: !prevOuvertes[periode],
        }));
    };

    const filtrerFactures = (data: any) => {
        let facturesFiltrees: any = {};

        Object.keys(data).forEach(key => {
            let facturesDuMois = data[key].filter((facture: any) => facture.nom.toLowerCase().includes("facture"));
            if (facturesDuMois.length > 0) {
                facturesFiltrees[key] = facturesDuMois;
            }
        });

        return facturesFiltrees;
    };

    const facturesFiltrees = filtrerFactures(facturesData);
    const periodesTriees = Object.keys(facturesFiltrees).sort((a, b) => {
        const dateA: any = new Date(a.split('-').reverse().join('-'));
        const dateB: any = new Date(b.split('-').reverse().join('-'));

        return dateB - dateA;
    });
    const telechargerFacture = (dateFacture: any, nomFacture: any, isZip: any) => {
        const dateFormatee = encodeURIComponent(dateFacture);
        const nomFactureEncode = encodeURIComponent(nomFacture);
        const url = `${BaseUrlConstants.BASE_URL}api/invoices/factures/telecharger/${dateFormatee}/${nomFactureEncode}/${isZip}/?X-AUTH-TOKEN=${getUrlFormatedToken()}`;
        window.open(url, '_blank');
    };

    if (isLoading) {
        return <LoaderOverlay loading/>
    }

    return (
        <div>
            <h2>Liste des Factures</h2>
            {periodesTriees.length > 0 ? (
                periodesTriees.map(periode => (
                    <div key={periode}>
                        <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                            <Button type="primary" shape="round" onClick={() => telechargerFacture(periode, 'a_zip_factures_'+periode+'.zip', 'oui')}
                                    style={{ marginRight: 10, marginTop: 4 }}>Télécharger ZIP</Button> <div onClick={() => toggleOuverture(periode)} style={{cursor: 'pointer'}}>{periode} {ouvertes[periode] ? '▼' : '▶'}</div>
                        </h3>
                        {ouvertes[periode] && (
                            <ul>
                                {
                                    facturesFiltrees[periode].map(
                                        (facture: any, index: any) => (
                                            <li
                                                key={index} style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'left'
                                                }}>
                                                <Button type="primary" shape="round" onClick={() => telechargerFacture(periode, facture.nom, undefined)}
                                                        style={{margin: 2}}>Télécharger
                                                </Button>
                                                <span style={{marginRight: 'auto', paddingLeft: 10}}>{facture.nom}</span></li>
                                        )
                                    )
                                }
                            </ul>
                        )}
                    </div>
                ))
            ) : (
                <div>Aucune facture disponible.</div>
            )}
        </div>
    );
}

export default Factures;
