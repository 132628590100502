import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

import './AddDeliveryZoneButton.scss';
import { Form, Modal, Select, Input, Button, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const CREATE_ZONE = gql`
    mutation createDeliveryZone($input: DeliveryZoneInput!) {
        createDeliveryZone(input: $input) {
            id
            name
            color
            points {
                lat
                lng
            }
        }
    }
`;

interface AddZoneFormProps {
    onCreate: () => void;
    employees: Employee[];
    clientType: string;
}

interface Employee {
    id: number;
    firstName: string;
    lastName: string;
}

export default function AddZoneForm({
    onCreate,
    employees,
    clientType,
}: AddZoneFormProps) {
    const [visible, setVisible] = useState(false);

    const [createZone, { loading }] = useMutation(CREATE_ZONE, {
        onCompleted: () => {
            onCreate();
            setVisible(false);
        },
        onError: (err: Error) => {
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    function onSubmit(values: any) {
        createZone({
            variables: { input: { ...values, clientType } },
        });
    }

    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setVisible(true)}
                className="add-delivery-zone-btn"
                shape="round"
            >
                Ajouter une zone
            </Button>
            <Modal
                title="Ajouter une zone"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                className="add-delivery-zone-modal"
            >
                <Form layout="vertical" onFinish={onSubmit} hideRequiredMark>
                    <Form.Item
                        name="name"
                        label="Nom de la zone"
                        rules={[
                            {
                                required: true,
                                message: 'Ce champ est requis',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="defaultDeliveryMan" label="Livreur">
                        <Select>
                            {employees?.map((employee: Employee) => (
                                <Select.Option
                                    value={employee.id}
                                    key={employee.id}
                                >
                                    {employee.firstName} {employee.lastName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            shape="round"
                        >
                            Enregistrer
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
