import React, { useState } from 'react';
import { Form, Button, Row, Col, InputNumber } from 'antd';
import InputNumberService from 'shared/services/InputNumberService';

interface CommandinvoiceFormProps {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSubmit: (values: any) => void;
    loading: boolean;
    refetch: () => void;
}

export default function CommandinvoiceForm({
    fields,
    onChange,
    onSubmit,
    loading,
    refetch,
}: CommandinvoiceFormProps) {

    const [, forceUpdate] = useState();
    const [form] = Form.useForm();

    const checkPrice = (_: any, value: { number: number }, fields: any) => {
        if (fields.apaPrice < fields.priceTotal) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Le champ ne peut pas être supérieur au montant total'));
    };

    return (
        <Form
            layout="vertical"
            form={form}
            fields={fields}
            onFieldsChange={(_, fields: any) => {
                onChange(fields);
            }}
            onFinish={onSubmit}
            hideRequiredMark
        >
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item
                        name="apaPrice"
                        label="Prix de l'APA TTC"
                        rules={[
                            { type: 'number', message: 'Le prix doit être une valeur numérique', validator: InputNumberService.inputNumberValidator,},
                            { required: true, message: 'Ce champ est requis' },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item
                        name="priceTotalPaid"
                        label="Montant total TTC déja payé"
                        rules={[
                            { type: 'number', message: 'Le prix doit être une valeur numérique', validator: InputNumberService.inputNumberValidator,},
                            { required: true, message: 'Ce champ est requis' },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item
                        name="priceTotalDue"
                        label="Montant total TTC restant"
                    >
                        <InputNumber readOnly={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={16}>
                    <Form.Item
                        name="priceTotal"
                        label="Montant total TTC"
                        
                        rules={[
                            { required: true, message: 'Ce champ est requis' },
                        ]}
                        
                    >
                        <InputNumber readOnly={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
                <Col offset={6} span={12}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            shape="round"
                            style={{
                                width: '95%',
                                padding: '5px',
                                height: '40px',
                                maxWidth: '300px',
                            }}
                        >
                            Enregistrer
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}


