import React from 'react';
import { useHistory } from 'react-router-dom';
import './WeekDeliveries.scss';
import { Button, notification, Popover } from 'antd';
import { StopOutlined } from '@ant-design/icons';

import moment from 'moment';
import DeliveryService from 'shared/services/DeliveryService';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import useClientTypes from 'shared/hooks/useClientTypes';

import { ClientIdentityAbsence } from './DeliveryList';
import AlertTag from 'shared/components/AlertTag';

interface WeekDeliveriesProps {
    summaries: Array<DeliverySummary>;
    deliveredDays: Array<DeliveredDay>;
    deliveryZone?: DeliveryZone;
    clientIdentityAbsences: ClientIdentityAbsence[];
    weekDays: Array<Date>;
    refetch: () => void;
}

interface DeliveryZone {
    id: Id;
}

const EDIT_DELIVERED_DAY = gql`
    mutation editDeliveredDay(
        $date: Date!
        $isDelivered: Boolean!
        $deliveryZone: UUID!
    ) {
        editDeliveredDay(
            date: $date
            isDelivered: $isDelivered
            deliveryZone: $deliveryZone
        ) {
            date
            isDelivered
        }
    }
`;

export default function WeekDeliveries({
    summaries,
    deliveredDays,
    weekDays,
    refetch,
    deliveryZone,
    clientIdentityAbsences,
}: WeekDeliveriesProps) {
    const history = useHistory();
    const clientTypes = useClientTypes();

    const [editDeliveredDay] = useMutation(EDIT_DELIVERED_DAY, {
        onCompleted: () => {
            notification.success({ message: 'Modification enregistrée' });
        },
        onError: (err: Error) => {
            console.error(err);
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    function isDeliveredDay(day: Date): boolean {
        const dateStr = moment(day).format('YYYY-MM-DD');
        return (
            deliveredDays.findIndex((deliveredDay) => {
                return (
                    deliveredDay.date === dateStr && deliveredDay.isDelivered
                );
            }) > -1
        );
    }

    return (
        <div className="week-deliveries">
            <table>
                <thead>
                    <tr>
                        {weekDays.map((day) => {
                            const deliveredDay = isDeliveredDay(day);

                            const mDay = moment(day);
                            const strDay = mDay.format('YYYY-MM-DD');
                            return (
                                <th>
                                    <div>
                                        <span>
                                            {`${mDay
                                                .format('dddd')
                                                .toUpperCase()} (${mDay.format(
                                                'DD',
                                            )})`}
                                        </span>
                                        {deliveryZone &&
                                            mDay.isAfter(moment()) && (
                                                <Button
                                                    icon={<StopOutlined />}
                                                    onClick={() => {
                                                        editDeliveredDay({
                                                            variables: {
                                                                date: strDay,
                                                                isDelivered: !deliveredDay,
                                                                deliveryZone:
                                                                    deliveryZone.id,
                                                            },
                                                        }).then(refetch);
                                                    }}
                                                    className={`__stop-icon ${
                                                        deliveredDay
                                                            ? ''
                                                            : '--off-day'
                                                    }`}
                                                />
                                            )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {clientTypes.map(({ code, label }, i) => (
                        <tr>
                            {weekDays.map((day) => {
                                const strDay = moment(day).format('YYYY-MM-DD');
                                const daySummary = summaries.find(
                                    (s) =>
                                        moment(s.date).format('YYYY-MM-DD') ===
                                            strDay && s.clientType === code,
                                );

                                const deliveredDay = isDeliveredDay(day);
                                const currentAbsences = clientIdentityAbsences.filter(
                                    (a) =>
                                        a.date === strDay &&
                                        a.clientIdentity.client.type === code,
                                );
                                return (
                                    (deliveredDay || i === 0) && (
                                        <td
                                            className={
                                                !deliveredDay
                                                    ? '--off-day'
                                                    : undefined
                                            }
                                            rowSpan={
                                                !deliveredDay
                                                    ? clientTypes.length
                                                    : undefined
                                            }
                                        >
                                            <div>
                                                {!deliveredDay ? (
                                                    <div>
                                                        <strong>
                                                            Pas de livraison
                                                        </strong>
                                                        <p>
                                                            Les commandes du
                                                            jour
                                                            <br />
                                                            seront livrées la
                                                            veille.
                                                        </p>
                                                    </div>
                                                ) : (
                                                    daySummary && (
                                                        <>
                                                            <h3 className="__header">
                                                                <span>
                                                                    {label}
                                                                </span>
                                                            </h3>
                                                            <div className="__content">
                                                                <div
                                                                    style={{
                                                                        marginBottom:
                                                                            '5px',
                                                                    }}
                                                                >
                                                                    {
                                                                        daySummary.deliveryCount
                                                                    }{' '}
                                                                    tournée
                                                                    {daySummary.deliveryCount >
                                                                    1
                                                                        ? 's'
                                                                        : ''}
                                                                    <br />
                                                                    {
                                                                        daySummary.clientCount
                                                                    }{' '}
                                                                    client
                                                                    {daySummary.clientCount >
                                                                    1
                                                                        ? 's'
                                                                        : ''}
                                                                    <br />
                                                                    {
                                                                        daySummary.mealCount
                                                                    }{' '}
                                                                    repas
                                                                    <br />
                                                                </div>

                                                                {currentAbsences.length >
                                                                    0 && (
                                                                    <Popover
                                                                        content={
                                                                            <div>
                                                                                {currentAbsences.map(
                                                                                    (
                                                                                        a,
                                                                                    ) => (
                                                                                        <div>{`${a.clientIdentity.firstname} ${a.clientIdentity.lastname}`}</div>
                                                                                    ),
                                                                                )}
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <div>
                                                                            <AlertTag
                                                                                message={`${
                                                                                    currentAbsences.length
                                                                                } client${
                                                                                    currentAbsences.length >
                                                                                    1
                                                                                        ? 's'
                                                                                        : ''
                                                                                } absent${
                                                                                    currentAbsences.length >
                                                                                    1
                                                                                        ? 's'
                                                                                        : ''
                                                                                }`}
                                                                                type="info"
                                                                            />
                                                                        </div>
                                                                    </Popover>
                                                                )}

                                                                {daySummary.clientToAssignCount !==
                                                                    0 && (
                                                                    <AlertTag message="Regénérer la tournée" />
                                                                )}
                                                                {daySummary.failedMealCount >
                                                                    0 && (
                                                                    <AlertTag
                                                                        message={`${
                                                                            daySummary.failedMealCount
                                                                        } commande ${
                                                                            daySummary.failedMealCount >
                                                                            1
                                                                                ? 's'
                                                                                : ''
                                                                        } non livrée ${
                                                                            daySummary.failedMealCount >
                                                                            1
                                                                                ? 's'
                                                                                : ''
                                                                        }`}
                                                                        noIcon
                                                                    />
                                                                )}
                                                            </div>
                                                            {<div className="__footer">
                                                                {/* <div
                                                                    className={`__delivery-status-tag --${daySummary.status}`}
                                                                >
                                                                    {DeliveryService.getDeliveryStatus(
                                                                        daySummary.status,
                                                                    )}{' '}
                                                                    {daySummary.status ===
                                                                        'in-progress' &&
                                                                        ` : ${daySummary.progressPercentage}%`}
                                                                </div> */}
                                                                <Button
                                                                    onClick={() =>
                                                                        history.push(
                                                                            {
                                                                                pathname: `/livraisons/${strDay}/${label.toLowerCase()}`,
                                                                            },
                                                                        )
                                                                    }
                                                                    type="ghost"
                                                                    shape="round"
                                                                    className="__handle-delivery-button"
                                                                >
                                                                    {`Gérer la tournée`}
                                                                </Button>
                                                            </div>}
                                                        </>
                                                    )
                                                )}
                                            </div>
                                        </td>
                                    )
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
