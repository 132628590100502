import React from 'react';
import { Tooltip } from 'antd';
import AlertTag from '../AlertTag';

interface Props {
    unassignedClients: {
        id: number;
        firstName: string;
        lastName: string;
    }[];
    message?: string;
}
export default function ClientsAlert({ unassignedClients, message }: Props) {
    if (!unassignedClients || unassignedClients.length === 0) {
        return null;
    }
    return (
        <Tooltip
            placement="top"
            title={unassignedClients.map((c) => (
                <div key={c.id}>{`${c.firstName} ${c.lastName}`}</div>
            ))}
        >
            <div className="client-alert">
                <AlertTag
                    message={`${unassignedClients.length} ${
                        unassignedClients.length === 1 ? 'client' : 'clients'
                    } ${message ? message : 'à assigner'}`}
                />
            </div>
        </Tooltip>
    );
}
