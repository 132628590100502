import React, {
    useState,
    useMemo,
    useRef,
    useEffect,
    ReactElement,
} from 'react';
import { Table, Button, Radio, Input, DatePicker, Checkbox } from 'antd';
import { TableProps, ColumnType, ColumnGroupType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import { Moment } from 'moment';

import './FilterDropdown.scss';
import ArrayService from '../../services/ArrayService';

function escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export default function FilterDropdown({ type, ...props }: any) {
    switch (type) {
        case 'text':
            return <FilterTextDropdown {...props} />;
        case 'date':
            return <FilterDateDropdown {...props} />;
        case 'select':
            return <FilterSelectDropdown {...props} />;
        default:
        // Nothing
    }
    return null;
}

function FilterSelectDropdown({
    column,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
}: any) {
    const [filterValue, setFilterValue] = useState([] as any);

    function handleclearFilter() {
        setFilterValue(undefined);
        clearFilters();
    }

    useEffect(() => {
        if (filterValue !== selectedKeys) {
            setSelectedKeys(filterValue);
        }
    }, [filterValue, selectedKeys, setSelectedKeys]);

    return (
        <div style={{ padding: 8 }} className="filter-select-dropdown">
            <Checkbox.Group
                options={column.filterOptions}
                value={selectedKeys}
                onChange={setFilterValue}
            />
            <div>
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 120, marginRight: 8 }}
                >
                    Rechercher
                </Button>
                <Button
                    onClick={handleclearFilter}
                    size="small"
                    style={{ width: 90 }}
                >
                    Vider
                </Button>
            </div>
        </div>
    );
}

function FilterDateDropdown({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
}: any) {
    const [filterValue, setFilterValue] = useState(
        undefined as [Moment, Moment] | undefined,
    );

    function handleclearFilter() {
        setFilterValue(undefined);
        clearFilters();
    }

    const selectedKey = selectedKeys.length > 0 ? selectedKeys[0] : null;
    useEffect(() => {
        if (!filterValue) {
            if (selectedKey) {
                setSelectedKeys([]);
            }
        } else {
            if (selectedKey !== filterValue) {
                setSelectedKeys([filterValue]);
            }
        }
    }, [filterValue, selectedKey, setSelectedKeys]);

    return (
        <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
                value={filterValue}
                onChange={(value: any) => {
                    setFilterValue(value);
                }}
                style={{
                    width: 300,
                    marginBottom: 8,
                    marginTop: 8,
                    //display: 'block',
                }}
            />
            <div>
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 120, marginRight: 8 }}
                >
                    Rechercher
                </Button>
                <Button
                    onClick={handleclearFilter}
                    size="small"
                    style={{ width: 90 }}
                >
                    Vider
                </Button>
            </div>
        </div>
    );
}

function FilterTextDropdown({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
}: any) {
    const [filterType, setFilterType] = useState('c');
    const [filterValue, setFilterValue] = useState(
        undefined as string | undefined,
    );
    const refInput = useRef(null);

    useEffect(() => {
        if (refInput.current) {
            setTimeout(() => {
                // @ts-ignore
                refInput.current.select();
            });
        }
    }, []);

    const selectedKey = selectedKeys.length > 0 ? selectedKeys[0] : null;

    useEffect(() => {
        if (!filterValue) {
            if (selectedKey) {
                setSelectedKeys([]);
            }
        } else {
            let fullValue = filterValue;
            switch (filterType) {
                case 'eq':
                    fullValue = '^' + escapeRegExp(fullValue) + '$';
                    break;
                case 'sw':
                    fullValue = '^' + escapeRegExp(fullValue);
                    break;
                case 'ew':
                    fullValue = escapeRegExp(fullValue) + '$';
                    break;
                case 'c':
                case 'reg':
                default:
                // Nothing to do
            }

            if (selectedKey !== fullValue) {
                setSelectedKeys([fullValue]);
            }
        }
    }, [filterType, filterValue, selectedKey, setSelectedKeys]);

    function handleclearFilter() {
        setFilterValue(undefined);
        setFilterType('c');
        clearFilters();
    }

    return (
        <div style={{ padding: 8 }}>
            <Radio.Group
                onChange={(e) => setFilterType(e.target.value)}
                value={filterType}
                size="small"
                buttonStyle="solid"
            >
                <Radio.Button value="eq">Égal à</Radio.Button>
                <Radio.Button value="c">Contient</Radio.Button>
                <Radio.Button value="sw">Commence par</Radio.Button>
                <Radio.Button value="ew">Finit par</Radio.Button>
                <Radio.Button value="reg">Regex</Radio.Button>
            </Radio.Group>
            <Input
                ref={refInput}
                placeholder={'Rechercher'}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                onPressEnter={() => confirm()}
                style={{
                    width: '100%',
                    marginBottom: 8,
                    marginTop: 8,
                    display: 'block',
                }}
            />
            <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 120, marginRight: 8 }}
            >
                Rechercher
            </Button>
            <Button
                onClick={handleclearFilter}
                size="small"
                style={{ width: 90 }}
            >
                Vider
            </Button>
        </div>
    );
}

export interface FilteredColumnType<T> extends ColumnType<T> {
    filter?: string;
    filterOptions?: { value: string; label: string | ReactElement }[];
    getFilterValue?: (record: T) => string | number;
}

export interface FilteredColumnGroupType<T>
    extends ColumnGroupType<T>,
        FilteredColumnType<T> {}

interface FilteredTableProps<T extends object> extends TableProps<T> {
    columns: (FilteredColumnGroupType<T> | FilteredColumnType<T>)[];
}

type filterType = (string | number)[] | null;
type filtersType = Record<string, filterType>;

function valueMatchFilter(value: string | number, filter: filterType): boolean {
    if (!filter) {
        return true;
    }

    return (
        filter.findIndex((filterValue) => {
            if (typeof filterValue === 'string' && typeof value === 'string') {
                const patt = new RegExp(filterValue, 'i');
                return patt.test(value);
            } else if (Array.isArray(value)) {
                return value.indexOf(filterValue) > -1;
            }
            return value === filterValue;
        }) > -1
    );
}

export function FilteredTable<T extends { [field: string]: any }>({
    columns,
    dataSource,
    ...props
}: FilteredTableProps<T>) {
    const [filters, setFilters] = useState({} as filtersType);
    const filteredDataSource = useMemo(() => {
        const keys = Object.keys(filters).filter((k) => {
            const filter = filters[k];
            return filter && filter.length > 0;
        });
        const columnsByKey: {
            [key: string]:
                | FilteredColumnType<T>
                | FilteredColumnGroupType<T>
                | undefined;
        } = {};
        keys.forEach((k) => {
            columnsByKey[k] = columns.find((c) => c.key === k);
        });
        // If there is not filters, we simply return the dataSource
        if (keys.length === 0 || !dataSource) {
            return dataSource;
        }
        return dataSource.filter((record) => {
            return (
                keys.findIndex((key) => {
                    const column = columnsByKey[key];
                    if (!column) {
                        return false;
                    }
                    let value: string | number;
                    if (column.getFilterValue) {
                        value = column.getFilterValue(record);
                    } else if (Array.isArray(column.dataIndex)) {
                        value = column.dataIndex.reduce(
                            (prev, curr) => (curr ? prev[curr] : prev),
                            record,
                        ) as any;
                    } else if (typeof column.dataIndex === 'string') {
                        value = record[column.dataIndex];
                    } else {
                        value = record[key];
                    }
                    return !valueMatchFilter(value, filters[key]);
                }) === -1
            );
        });
    }, [dataSource, filters]);

    return (
        <Table
            {...props}
            dataSource={filteredDataSource}
            columns={
                columns
                    ? columns.map((column) => {
                          let newColumn = { filterIcon: <SearchOutlined />, ...column };

                          if (column.filter) {
                              newColumn.filterDropdown = (props) => (
                                  <FilterDropdown
                                      type={column.filter}
                                      column={column}
                                      {...props}
                                  />
                              );
                          }
                          if (column.sorter === true) {
                              if (column.getFilterValue) {
                                  newColumn.sorter = ArrayService.cmpBy(
                                      column.getFilterValue,
                                  );
                              } else if (typeof column.dataIndex === 'string') {
                                  newColumn.sorter = ArrayService.cmpBy(
                                      column.dataIndex,
                                  );
                              }
                          }
                          return newColumn;
                      })
                    : undefined
            }
            onChange={(pagination, filters, sorter) => {
                setFilters(filters);
            }}
        />
    );
}
