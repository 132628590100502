import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const GET_DISH_TYPES = gql`
    {
        dishTypes {
            code
            label
        }
    }
`;

interface DishType {
    code: string;
    label: string;
}

export default function useDishTypes() {
    const { data } = useQuery<{ dishTypes: DishType[] }>(GET_DISH_TYPES);

    return data ? data.dishTypes : [];
}
