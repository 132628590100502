import React from 'react';
import { Tag } from 'antd';

export const statusLabelMap: { [status: string]: string } = {
    enabled: 'Compte actif',
    disabled: 'Compte désactivé',
};

export const statusInvoiceLabelMap: { [status: string]: string } = {
    enabled: 'Importé',
    disabled: 'Non importé',
};

const statusColorMap: { [status: string]: string | undefined } = {
    enabled: 'green',
    disabled: undefined,
};

export default function StatusTag({
    status
}: { status: string }) {
    return <Tag color={statusColorMap[status]}>{statusLabelMap[status]}</Tag>;
}