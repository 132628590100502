import StringService from './StringService';

export default class ArrayService {
    static filterUniqueEntity(
        value: { id: Id | number },
        index: number,
        self: { id: Id | number }[],
    ) {
        return self.findIndex((v) => v.id === value.id) === index;
    }

    static filterUniqueBy<T extends { [field: string]: any }>(
        field: string | ((e: T) => any),
    ) {
        function getValue(e: T, field: string | string[] | ((e: T) => any)) {
            return Array.isArray(field)
                ? field.reduce((prev, curr) => `${prev}${e[curr]}`, '')
                : typeof field === 'string'
                ? e[field]
                : field(e);
        }

        return (value: T, index: number, self: T[]) => {
            return (
                self.findIndex(
                    (v) => getValue(v, field) === getValue(value, field),
                ) === index
            );
        };
    }

    static filterUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }

    static uniqueArray(array: Array<any>) {
        return array.filter(ArrayService.filterUnique);
    }

    static cmpBy<T extends { [field: string]: any }>(
        field: string | string[] | ((e: T) => any),
        desc?: boolean
    ) {
        function getValue(e: T, field: string | string[] | ((e: T) => any)) {
            return Array.isArray(field)
                ? field.reduce((prev, curr) => `${prev}${e[curr]}`, '')
                : typeof field === 'string'
                ? e[field]
                : field(e);
        }
        return (e1: T, e2: T) => {
            const v1 = getValue(e1, field);
            const v2 = getValue(e2, field);

            if (typeof v1 === 'string' || typeof v2 === 'string') {
                return StringService.compareCaseInsensitive(v1, v2) * (desc ? -1 : 1);
            }
            return (v1 > v2 ? 1 : -1) * (desc ? -1 : 1);
        };
    }
}
