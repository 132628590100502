import React from 'react';
import { Button } from 'antd';
//import BugTracker from "./services/BugTracker";
import './ErrorBoundary.scss';

interface Props {}

interface State {
    error: boolean;
    message: string;
    detailsVisible: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: false,
            message: '',
            detailsVisible: false,
        };
    }

    componentDidCatch(err: any, errInfo: any) {
        console.error(err);
        console.error(errInfo);
        //BugTracker.notify(err);
        this.setState({
            error: true,
            message: err.message,
        });
    }

    render() {
        const { error, message, stack, detailsVisible } = this.state;
        if (error) {
            return (
                <div className="error-boundary">
                    <h1
                        style={{
                            textAlign: 'center',
                            fontSize: 18,
                            padding: 10,
                        }}
                    >
                        {'Une erreur est survenue'}
                    </h1>
                    <p>L'application a cessé de fonctionner.</p>
                    <div className="__action">
                        <Button
                            shape="round"
                            type="primary"
                            size="large"
                            onClick={() => window.location.reload()}
                        >
                            Recharger la page
                        </Button>
                    </div>

                    <Button
                        shape="round"
                        type="link"
                        size="large"
                        onClick={() =>
                            this.setState((prev) => ({
                                detailsVisible: !prev.detailsVisible,
                            }))
                        }
                    >
                        {`${detailsVisible ? 'Masquer' : 'Voir'} détail`}
                    </Button>
                    {detailsVisible && (
                        <div className="__details">
                            <div className="__message">{message}</div>
                        </div>
                    )}
                </div>
            );
        }
        return this.props.children;
    }
}
