import React, {useEffect, useState} from "react";
import {Modal, Tag, Row, Col, Typography} from "antd";
import LoaderOverlay from "shared/components/LoaderOverlay";
import BaseUrlConstants from "shared/constants/BaseUrlConstants";
import {getAuthToken} from "shared/contexts/AuthContext";

export interface Invoice {
    id: number;
    status: boolean;
    client: string;
    meals: InvoiceMeals[];
    meals_total: InvoiceMealsTotal[];
    supplements: InvoiceSupplement[];
    supplements_total: InvoiceSupplementsTotal[];
}

export interface InvoiceMeals {
    id: number;
    name: string;
    price: number;
    quantity: number;
    tva: string;
    totalPrice: number;
}

export interface InvoiceMealsTotal {
    price: number;
    quantity: number;
}

export interface InvoiceSupplement {
    id: number;
    name: string;
    price: number;
    quantity: number;
    tva: string;
    totalPrice: number;
}

export interface InvoiceSupplementsTotal {
    price: number;
    quantity: number;
}

export default function InvoiceShowModal({invoiceId, visible, onClose}: {
    invoiceId: number,
    visible: boolean;
    onClose: () => void;
}) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Invoice>({} as Invoice);
    const {Text} = Typography;

    useEffect(() => {
        fetch(BaseUrlConstants.BASE_URL + 'api/invoices/show/' + invoiceId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': getAuthToken().value,
            }
        }).then(r => r.json())
            .then(data => {
                setData(data)
            }).finally(() => {
            setLoading(false)
        })
    }, []);

    if (loading) return <LoaderOverlay loading/>

    return (
        <Modal
            className="edit-employee-modal"
            title="Informations de facturation"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <>
                <div>
                    <h2>Client : {data.client} <Tag
                        color={data.status == true ? 'blue' : 'volcano'}>{data.status ? 'Importé' : 'Non importé'}</Tag>
                    </h2>
                </div>

                <br/>

                <div>
                    <h2>Menus</h2>

                    <Row>
                        <Col span={7}><Text>Nom</Text></Col>
                        <Col span={4}><Text>Prix</Text></Col>
                        <Col span={4}><Text>Quantité</Text></Col>
                        <Col span={4}><Text>Montant TVA</Text></Col>
                        <Col span={4}><Text>Total TTC</Text></Col>
                    </Row>

                    {data.meals.map((menu, index) => (
                        <Row key={index}>
                            <Col span={7}><Text type="secondary">{menu.name}</Text></Col>
                            <Col span={4}><Text type="secondary">{menu.price} €</Text></Col>
                            <Col span={4}><Text type="secondary">{menu.quantity}</Text></Col>
                            <Col span={4}><Text type="secondary">{(Math.round(((menu.totalPrice * parseFloat(menu.tva)) / 100) *100)/100).toLocaleString()} €</Text></Col>
                            <Col span={4}><Text type="secondary">{(Math.round(menu.totalPrice * 100)/100).toLocaleString()} € </Text></Col>
                        </Row>
                    ))}

                    
                    {data.meals_total.map((menu, index) => (
                        <Row key={index}>
                            <Col span={7} style={{ fontWeight: 'bold' }}><Text type="secondary">Total</Text></Col>
                            <Col span={4}><Text type="secondary"></Text></Col>
                            <Col span={4} style={{ fontWeight: 'bold' }}><Text type="secondary">{menu.quantity}</Text></Col>
                            <Col span={4} style={{ fontWeight: 'bold' }}><Text type="secondary">{(Math.round(((menu.price * 5.5) / 100) *100)/100).toLocaleString()} €</Text></Col>
                            <Col span={4} style={{ fontWeight: 'bold' }}><Text type="secondary">{(Math.round(menu.price * 100)/100).toLocaleString()} € </Text></Col>
                        </Row>
                    ))}
                </div>

                <br/>

                <div>
                    <h2>Suppléments</h2>

                    {data.supplements ?
                        <>
                            <Row>
                                <Col span={7}><Text>Nom</Text></Col>
                                <Col span={4}><Text>Prix</Text></Col>
                                <Col span={4}><Text>Quantité</Text></Col>
                                <Col span={4}><Text>Montant TVA</Text></Col>
                                <Col span={4}><Text>Total TTC</Text></Col>
                            </Row>

                            {data.supplements.map((supplement, index) => (
                                <Row key={index}>
                                    <Col span={7}><Text type="secondary">{supplement.name}</Text></Col>
                                    <Col span={4}><Text type="secondary">{supplement.price} €</Text></Col>
                                    <Col span={4}><Text type="secondary">{supplement.quantity}</Text></Col>
                                    <Col span={4}><Text type="secondary">{(Math.round(((supplement.totalPrice * parseFloat(supplement.tva)) / 100) *100)/100).toLocaleString()} €</Text></Col>
                                    <Col span={4}><Text type="secondary">{(Math.round(supplement.totalPrice* 100)/100).toLocaleString()} €</Text></Col>
                                </Row>
                            ))}

                            {data.supplements_total.map((supplement, index) => (
                                <Row key={index}>
                                    <Col span={7} style={{ fontWeight: 'bold' }}><Text type="secondary">Total</Text></Col>
                                    <Col span={4}><Text type="secondary"></Text></Col>
                                    <Col span={4} style={{ fontWeight: 'bold' }}><Text type="secondary">{supplement.quantity}</Text></Col>
                                    <Col span={4} style={{ fontWeight: 'bold' }}><Text type="secondary">{(Math.round(((supplement.price * 5.5) / 100) *100)/100).toLocaleString()} €</Text></Col>
                                    <Col span={4} style={{ fontWeight: 'bold' }}><Text type="secondary">{(Math.round(supplement.price* 100)/100).toLocaleString()} € </Text></Col>
                                </Row>
                            ))}
                        </>
                        : "Aucun supplément"}

                </div>
            </>
        </Modal>
    )
}