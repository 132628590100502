import React from 'react';
import { Tag } from 'antd';
import { WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';

import './AlertTag.scss';

interface Props {
    message: string;
    noIcon?: boolean;
    type?: 'info' | 'warning';
    onClick?: () => void;
}
const AlertTag: React.FunctionComponent<Props> = ({
    message,
    noIcon,
    type,
    onClick,
}) => {
    return (
        <Tag
            className="alert-tag"
            color={type == 'info' ? 'blue' : 'error'}
            onClick={onClick}
        >
            {!noIcon &&
                (type === 'info' ? (
                    <InfoCircleOutlined />
                ) : (
                    <WarningOutlined />
                ))}
            {message}
        </Tag>
    );
};
export default AlertTag;
