import React, { useState } from 'react';
import './DaySelect.scss';
import { Tooltip, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import StringService from 'shared/services/StringService';

interface Props {
    value: moment.Moment;
    onChange: (v: moment.Moment) => void;
}

export default function DaySelect({ value, onChange }: Props) {
    function previousDay() {
        onChange(moment(value).subtract(1, 'day'));
    }

    function nextDay() {
        onChange(moment(value).add(1, 'day'));
    }

    return (
        <div className="day-select">
            <Tooltip title="Jour précédent">
                <Button
                    shape="circle"
                    icon={<LeftOutlined />}
                    onClick={previousDay}
                />
            </Tooltip>
            <div className="__day">
                <b>{StringService.capitalize(value.format('dddd'))}</b>
                <p>{`${value.format('DD')} ${StringService.capitalize(value.format('MMM'))} ${value.format('Y')}`}</p>
            </div>
            <Tooltip title="Jour suivant">
                <Button
                    shape="circle"
                    icon={<RightOutlined />}
                    onClick={nextDay}
                />
            </Tooltip>
        </div>
    );
};