import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

export const HOME_LATLNG = { lat: 43.810238, lng: 4.405238 };

export default function HomeMarker() {
    return (
        <Marker
            position={[HOME_LATLNG.lat, HOME_LATLNG.lng]}
            icon={L.divIcon({
                html: `<div class="home-marker"><img alt="La Cigale" src="logo512.png" /><div class="__arrow"></div></div>`,
            })}
        ></Marker>
    );
}
