import React, {useEffect, useState} from "react";
import {useFetch} from "../../hooks/useFetch";
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Tag from "antd/lib/tag";
import MonthSelect from "../../components/MonthSelect";
import moment from 'moment';
import LoaderOverlay from "shared/components/LoaderOverlay";
import {Tooltip, Button, notification} from "antd";
import InvoiceShowModal, {Invoice} from "./InvoiceGetModal";
import InvoiceEmailModal from "views/client/clientHistoricInvoicing/InvoiceEmailModal";
import EditInvoiceModal from "views/client/clientHistoricInvoicing/EditInvoiceModal";
import {MouseEvent} from "react"
import BaseUrlConstants from "shared/constants/BaseUrlConstants";
import {getAuthToken, getUrlFormatedToken} from "shared/contexts/AuthContext";
import { EditOutlined, FilePdfOutlined, EyeOutlined, MailOutlined } from '@ant-design/icons';
import { FilteredTable } from "shared/components/table/FilterDropdown";
import ArrayService from "shared/services/ArrayService";
import { statusInvoiceLabelMap } from "shared/components/StatusTag";
import StatusInvoiceTag from "shared/components/StatusInvoiceTag";
import './invoices.css';

const GET_DATA = gql`
    query getData($periode: String!) {
        commandInvoices(periode: $periode) {
            id
            client {
                id
                firstName
                lastName
                defaultDeliveryZone {
                    id
                    name
                    color
                    defaultDeliveryMan {
                        id
                        firstName
                        lastName
                    }
                }
            }
            periode
            status
            nameInvoice
            nameCreditNote
            apaPrice
            invoiceStatus
            sentAt
            priceTotal
            priceTotalPaid
            priceTotalDue
            commandInvoiceEmails{
                id
                sentAt
            }
        }
    }
`;

const SEND_INVOICES_EMAIL = gql`
    mutation sendInvoices($ids: [Int!]) {
        sendInvoices(ids: $ids) {
            id
        }
    }
`;


export default function Invoices()  {

    const [monthValue, setMonthValue] = useState(nowDate() as string);
    const [selectionType] = useState('checkbox' as any);
    const [invoiceId, setInvoiceid] = useState(null as number | null);
    const [invoice, setInvoiceId] = useState(null as number | null);
    const [invoiceIdd, setInvoiceIdd] = useState(null as number | null);
    const [idInvoice, setIdInvoice] = useState([] as number[] | null);
    const [commandInvoices, setCommandInvoices] = useState<Array<CommandInvoice>>([]);
    const { loading, error, data, refetch } = useQuery<{ 
        commandInvoices : CommandInvoice[];
    }>(
        GET_DATA,
        {
            variables: {
                periode: monthValue,
            },
        },
    );

    const columns = [
        {
            title: 'Envoyé',
            dataIndex: 'commandInvoiceEmails',
            key: 'commandInvoiceEmails',
            sorter: ArrayService.cmpBy(
                (i: CommandInvoice) =>
                    statusInvoiceLabelMap[i.commandInvoiceEmails?.length > 0 ? 'disabled' : 'enabled'],
            ),
            getFilterValue: (i: CommandInvoice) => (i.commandInvoiceEmails?.length > 0 ? 'disabled' : 'enabled'),
            filter: 'select',
            filterOptions: Object.keys(statusInvoiceLabelMap).map((commandInvoiceEmails) => ({
                value: commandInvoiceEmails,
                label: <Tag color={commandInvoiceEmails === 'disabled' ? 'green' : 'volcano'}>{commandInvoiceEmails === "disabled" ? 'OUI' : 'NON'}</Tag>,
            })),
            render: (_: any, invoice: CommandInvoice) => (
                <StatusInvoiceTag statusInvoice={invoice.commandInvoiceEmails?.length > 0 ? 'sent' : 'not_sent'} />) 
        },
        {
            title: 'Nom client',
            key: 'client',
            sorter: true,
            getFilterValue: (row: CommandInvoice) =>
                row.client &&
                `${row.client.firstName} ${row.client.lastName}`,
            filter: 'text',
            render: (row: CommandInvoice) =>
                row.client &&
                `${row.client.firstName} ${row.client.lastName.toUpperCase()}`,
        },
        {
            title: 'Nom Facture',
            dataIndex: 'nameInvoice',
            key: 'nameInvoice'
        },
        {
            title: 'Statut',
            dataIndex: 'invoiceStatus',
            key: 'invoiceStatus',
            render: (_: any, invoice: CommandInvoice) => (
                <StatusInvoiceTag statusInvoice={invoice.invoiceStatus == 1 ? 'enabled' : 'disabled'} />) 
        },
        {
            title: 'Tournée',
            key: 'defaultDeliveryMan',
            sorter: true,
            getFilterValue: (row: CommandInvoice) =>
                row.client &&
                `${row.client.defaultDeliveryZone?.defaultDeliveryMan.firstName} ${row.client.defaultDeliveryZone?.defaultDeliveryMan.lastName}`,
            filter: 'text',
            render: (row: CommandInvoice) =>
                row.client &&
                `${row.client.defaultDeliveryZone?.defaultDeliveryMan.firstName} ${row.client.defaultDeliveryZone?.defaultDeliveryMan.lastName.toUpperCase()}`,
        },
        {
            title: 'Montant APA TTC',
            dataIndex: 'apaPrice',
            key: 'apaPrice'
        },
        {
            title: 'Montant payé TTC',
            dataIndex: 'priceTotalPaid',
            key: 'priceTotalPaid'
        },
        {
            title: 'Montant restant TTC',
            dataIndex: 'priceTotalDue',
            key: 'priceTotalDue'
        },
        {
            title: 'Montant Total TTC',
            dataIndex: 'priceTotal',
            key: 'priceTotal',
        },
        {
            key: 'action',
            width: 100,
            render: (_: any, commandInvoice: CommandInvoice) => (
                <span className="__actions">
                    <Tooltip title="Détail de facturation">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<EyeOutlined/>}
                            ghost
                            style={{marginRight: 8}}
                            onClick={() => setInvoiceid(commandInvoice.id)}
                        />
                    </Tooltip>
                    <Tooltip title="Editer la facture">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            ghost
                            style={{ marginRight: 8 }}
                            onClick={() => setInvoiceId(commandInvoice.id)}
                        />
                    </Tooltip>
                    <Tooltip title="Facture PDF">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<FilePdfOutlined />}
                            ghost
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                const url = `${BaseUrlConstants.BASE_URL}api/invoices${commandInvoice.id}.pdf`;
                                    /* global window */
                                    window.open(
                                        `${url}?X-AUTH-TOKEN=${getUrlFormatedToken()}`,
                                        '_blank',
                                    );refetch();
                            } 
                        }
                        />
                    </Tooltip>
                    <Tooltip title="Avoir PDF">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<FilePdfOutlined />}
                            ghost
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                const url = `${BaseUrlConstants.BASE_URL}api/avoir${commandInvoice.id}.pdf`;
                                    /* global window */
                                    window.open(
                                        `${url}?X-AUTH-TOKEN=${getUrlFormatedToken()}`,
                                        '_blank',
                                    );
                            } }
                        />
                    </Tooltip>
                    {commandInvoice.nameInvoice ?
                    <Tooltip title="Envoyer la facture par E-mail">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<MailOutlined />}
                            ghost
                            style={{ marginRight: 8 }}
                            onClick={() => setInvoiceIdd(commandInvoice.id)}
                        />
                    </Tooltip>
                    : null}
                </span>
            ),
        },
    ];
 
    useEffect(() => {
        refetch();
        if (data && data.commandInvoices) {
            setCommandInvoices(data.commandInvoices);
        }
    }, [data, refetch]);

    const rowSelection = {
        onChange: (selectedRowKeys: any) => {
            setIdInvoice(selectedRowKeys)
        },
        getCheckboxProps: (invoice: CommandInvoice) => ({
            disabled: invoice.commandInvoiceEmails?.length > 0 || invoice.nameInvoice == null, // Column configuration not to be checked
        }),
    };

    const [sendInvoicesToCustomer] = useMutation(SEND_INVOICES_EMAIL, {

            
        variables: {
            ids: idInvoice
        },
            
        onCompleted: () => {
            notification.success({ message: 'Les factures selectionnées ont été envoyées' });
            refetch();
        },
        onError: (err: Error) => {
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    // const handleExporterFactures = async (e: MouseEvent) => {
    //     e.preventDefault();
    //     let timeout1: number;
    //     function fetchWithTimeout({resource, options = {}, timeout = 50000}: {
    //         resource: any,
    //         options?: {},
    //         timeout?: number
    //     }) {
    //         const { signal } = new AbortController();
    //         const id = setTimeout(() => signal.aborted, timeout);

    //         return fetch(resource, { ...options, signal })
    //             .finally(() => clearTimeout(id));
    //     }

    // }

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const fileName = `relevé-facturation-${formattedDate}.csv`;
    const handleExportFactures = async (e: MouseEvent) => {
        e.preventDefault()
        try {
        if (data) {
            await fetch(
                `${
                    BaseUrlConstants.BASE_URL
                }api/invoices/export/invoices/${monthValue}?X-AUTH-TOKEN=${getUrlFormatedToken()}`,
            ).then((res: any) =>
                res.blob().then((blob: any) => {
                    downloadBlob(blob, `${fileName}`);
                    if (res?.status === 200) {
                       // window.location.reload();
                        notification.success({message: 'Les factures ont été exportées en CSV'});
                    } else {
                        notification.error({message: res.error.message});
                    }
                }),
            );
        }
        } catch (error) {
            console.error('Error exporting invoices:', error);
            notification.error({message: 'Une erreur s\'est produite lors de l\'export des factures.'});
        }
    }
    

    return (
        <div className="senior-commands">
            <div className="__header">
                <h2>Facturation</h2>
                <div>
                    <MonthSelect
                        onChange={ (v) => {setMonthValue(v);} }
                        value={monthValue}
                    />
                </div>
                <div>
                    <div className="actions-container">
                        <div className="actions-left">
                                {/* {progression > 0 && progression < 100 ?
                                    <BarreDeProgression progression={progression}/> : null} */}
                            {/* <Button
                                onClick={(e) => handleExporterFactures(e)}
                                type="primary"
                                htmlType="submit"
                                shape="round"
                            >
                                Générer les factures
                            </Button> */}

                            <Button
                                onClick={(e) => handleExportFactures(e)}
                                type="primary"
                                htmlType="submit"
                                shape="round"
                            >
                                Exporter les données de facturation (.csv)
                            </Button>
                        </div>
                        <div className="actions-right">
                            <Button
                                onClick={() => sendInvoicesToCustomer()}
                                type="primary"
                        htmlType="submit"
                        shape="round"
                        >
                            Envoyer les factures
                        </Button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>
            {loading && <LoaderOverlay loading/>}
                <FilteredTable
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    rowKey="id"
                    dataSource={commandInvoices} 
                    columns={columns}
                />
            </div>
            {invoiceId ? (
                <InvoiceShowModal
                    invoiceId={invoiceId}
                    visible={!!invoiceId}
                    onClose={() => setInvoiceid(0)}
                />
            ) : null}
            <EditInvoiceModal
                invoiceId={invoice}
                visible={!!invoice}
                onClose={() => setInvoiceId(0)}
            />
            {invoiceIdd ? (
            <InvoiceEmailModal
                invoiceId={invoiceIdd}
                visible={!!invoiceIdd}
                onClose={() => setInvoiceIdd(0)}
            />
        ) : null}
        </div>
    )
}

const nowDate = (): string => {
    return moment().format('MM-YYYY');
}

function downloadBlob(blob: any, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
