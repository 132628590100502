import React, { useState } from 'react';
import DeliveryZoneMap from './DeliveryZoneMap';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import './ActiveDeliveryZone.scss';
import { Button, notification } from 'antd';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import DeliveryZoneItem, { DeliveryZoneInput } from './DeliveryZoneItem';
import AddZoneButton from './AddDeliveryZoneButton';

const GET_DATA = gql`
    {
        deliveryZones(clientType: "active") {
            id
            name
            color
            points {
                lat
                lng
            }
            defaultDeliveryMan {
                id
            }
        }

        employees(roles: ["delivery-man"]) {
            id
            firstName
            lastName
        }
    }
`;

interface DeliveryZone {
    id: Id;
    name: string;
    color: string;
    points: {
        lat: number;
        lng: number;
    }[];
    defaultDeliveryMan: {
        id: number;
    } | null;
}

interface Employee {
    id: number;
    firstName: string;
    lastName: string;
}

interface Data {
    deliveryZones: DeliveryZone[];
    employees: Employee[];
}

const EDIT_ZONE = gql`
    mutation editDeliveryZone($id: UUID!, $input: DeliveryZoneInput!) {
        editDeliveryZone(id: $id, input: $input) {
            id
            name
            color
            points {
                lat
                lng
            }
            defaultDeliveryMan {
                id
                firstName
                lastName
            }
        }
    }
`;

const DELETE_ZONE = gql`
    mutation deleteDeliveryZone($id: UUID!) {
        deleteDeliveryZone(id: $id)
    }
`;

export default function DeliveryZone() {
    const [selectedZoneId, setSelectedZoneId] = useState(null as string | null);
    const { loading, data, refetch } = useQuery<Data>(GET_DATA);

    const [editZone] = useMutation(EDIT_ZONE, {
        onError: (err: Error) => {
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    const [deleteZone] = useMutation(DELETE_ZONE, {
        onCompleted: () => {
            refetch();
        },
        onError: (err: Error) => {
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    function handleZoneDelete(zoneId: string) {
        deleteZone({
            variables: {
                id: zoneId,
            },
        });
    }

    function handleZoneChange(zone: DeliveryZoneInput) {
        editZone({
            variables: {
                id: zone.id,
                input: {
                    name: zone.name,
                    points: zone.points?.map((p) => ({
                        lat: p.lat.toFixed(4),
                        lng: p.lng.toFixed(4),
                    })),
                    defaultDeliveryMan: zone.defaultDeliveryMan,
                },
            },
        });
    }

    const deliveryZones = data ? data.deliveryZones : [];

    const selectedZone = deliveryZones.find((z) => z.id === selectedZoneId);

    return (
        <div className="active-delivery-zone">
            <h2>Zones de livraison : Actifs</h2>
            <div className="__content">
                <div className="__zone-list">
                    <AddZoneButton
                        onCreate={refetch}
                        employees={data ? data.employees : []}
                        clientType="active"
                    />

                    {loading && <LoaderOverlay loading />}

                    {deliveryZones.map((zone) => (
                        <DeliveryZoneItem
                            key={zone.id}
                            zone={zone}
                            isSelected={zone.id === selectedZoneId}
                            onSelect={() =>
                                setSelectedZoneId(
                                    zone.id === selectedZoneId ? null : zone.id,
                                )
                            }
                            onDelete={() => handleZoneDelete(zone.id)}
                            onChange={handleZoneChange}
                            employees={data ? data.employees : []}
                        />
                    ))}
                    {selectedZone && (
                        <Button
                            onClick={() => setSelectedZoneId(null)}
                            shape="round"
                            className="__deselect-zone-btn"
                        >
                            Terminer le tracé
                        </Button>
                    )}
                </div>
                <div className="__round-map">
                    <div
                        className={`__selected-zone-indicator ${
                            selectedZone ? '--selected-zone' : ''
                        }`}
                        style={
                            selectedZone
                                ? { backgroundColor: selectedZone.color }
                                : undefined
                        }
                    />
                    <DeliveryZoneMap
                        zones={deliveryZones}
                        onZoneChange={handleZoneChange}
                        selectedZoneId={selectedZoneId}
                    />
                </div>
            </div>
        </div>
    );
}
