import React, { useState, useEffect } from 'react';
import { Modal, notification, Form } from 'antd';
import CommandInvoiceForm from './CommandInvoiceForm';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ObjectService from 'shared/services/ObjectService';

interface EditCommandinvoiceModalProps {
    invoiceId: number | null;
    visible: boolean;
    onClose: () => void;
}

const GET_DATA = gql`
    query getData($id: Int!) {
        commandInvoice(id: $id) {
        id
        invoiceStatus
        apaPrice
        priceTotalPaid
        priceTotalDue
        priceTotal
        }
    }
`;

const EDIT_COMMAND_INVOICE = gql`
    mutation editCommandInvoice($id: Int!, $input: CommandInvoiceInput!) {
        editCommandInvoice(id: $id, input: $input) {
            id
            invoiceStatus
            apaPrice
            priceTotalPaid
            priceTotalDue
            priceTotal
        }
    }
`;

export default function EditCommandinvoiceModalModal({
    visible,
    invoiceId,
    onClose
}: EditCommandinvoiceModalProps) {

    const [formLoading, setFormLoading] = useState(false);
    const [fields, setFields] = useState([] as any[]);
    const [form] = Form.useForm();

    const { data, refetch } = useQuery<{ commandInvoice: CommandInvoice }>(
        GET_DATA,
        {
            variables: {
                id: invoiceId,
            },
        },
    );

    useEffect(() => {
        if (data && data.commandInvoice) {
            form.resetFields();
            const fields = ObjectService.mapFormFieldsObject(data.commandInvoice, {
            });

            setFields(fields);
        }
    }, [data, refetch]);

    const [editCommandinvoice] = useMutation(EDIT_COMMAND_INVOICE, {
        onCompleted: () => {
            notification.success({ message: 'Facture modifiée' });
            refetch();
        },
        onError: (err: Error) => {
            setFormLoading(false);
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    function handleSubmit(values: any) {
        setFormLoading(true);
        let invoiceValues: any = { ...values };
        if(invoiceValues.apaPrice < 0) {
            notification.error({
                message: 'Erreur',
                description: 'Le prix de l’APA TTC doit être positif.',
                duration: 4.5,
            });

            setFormLoading(false);
            return;
        }
        if(invoiceValues.priceTotalPaid < 0) {
            notification.error({
                message: 'Erreur',
                description: 'Le montant Payé TTC doit être positif.',
                duration: 4.5,
            });

            setFormLoading(false);
            return;
        }
        if((invoiceValues.apaPrice + invoiceValues.priceTotalPaid) - invoiceValues.priceTotalDue > (invoiceValues['priceTotal']) || (invoiceValues['priceTotal'] - invoiceValues['priceTotalPaid'] - invoiceValues['apaPrice']) < 0) {
            notification.error({
                message: 'Erreur',
                description: 'Le prix remboursé dépasse le prix total.',
                duration: 4.5,
            });

            setFormLoading(false);
            return;
        }
        invoiceValues['priceTotalDue'] = (invoiceValues['priceTotal'] - invoiceValues['priceTotalPaid'] - invoiceValues['apaPrice']);
        
        if(invoiceValues.priceTotalDue == '0'){
            invoiceValues.invoiceStatus = 1;
        }else{
            invoiceValues.invoiceStatus = 0;
        }

        editCommandinvoice({

            variables: {
                id: invoiceId,
                input: invoiceValues,
            },
            
        }).then(refetch).catch(error => {
            notification.error({
                message: 'Erreur : ' + error.message(),
                description: 'Erreur lors de l’enregistrement.',
                duration: 4.5,
            });
        }).finally(() => setFormLoading(false));
    }

    return (
        <Modal
            className="edit-invoice-modal"
            title="Editer une facture"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <CommandInvoiceForm
                onChange={setFields}
                fields={fields}
                onSubmit={handleSubmit}
                loading={formLoading}
                refetch={refetch}
            />
        </Modal>

    )

}