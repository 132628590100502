import React, { useState, useEffect } from 'react';
import { Modal, notification, Form } from 'antd';
import CommandInvoiceSendForm from './CommandInvoiceSendForm';
import { gql } from 'apollo-boost';
import './HistoricInvoicing.scss';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ObjectService from 'shared/services/ObjectService';
import { FilteredTable } from 'shared/components/table/FilterDropdown';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import moment from 'moment';

interface SendCommandinvoiceEmailModalProps {
    invoiceId: number | null;
    visible: boolean;
    onClose: () => void;
}

interface CommandInvoiceEmail {
    id: number;
    commandInvoice: {
        id: number;
    }
    sentBy: {
        id: number;
        firstName: string;
        lastName: string;
    }
    sentTo: string;
    sentToBis: string;
    sentAt: string;
}

const GET_DATA = gql`
    query getData($id: Int!) {
        commandInvoice(id: $id) {
            nameInvoice
            client {
                email
                emailBis
            }
            commandInvoiceEmails{
                id
                sentAt
            }
        }
    }
`;

const GET_DATA_EMAIL = gql`
    query getData($id: Int!) {
        commandInvoicesEmail(commandInvoice: $id) {
            sentTo
            sentToBis
            sentBy{
                id
                firstName
                lastName
            }
            sentAt
        }
    }
`;

const CREATE_COMMAND_INVOICE_EMAIL = gql`
    mutation createCommandInvoiceEmail($input: CommandInvoiceEmailInput!) {
        createCommandInvoiceEmail(input: $input) {
            commandInvoice{
                id
            }
            sentTo
            sentToBis
        }
    }
`;

export default function SendCommandinvoiceEmailModal({
    visible,
    invoiceId,
    onClose
}: SendCommandinvoiceEmailModalProps) {

    const [formLoading, setFormLoading] = useState(false);
    const [fields, setFields] = useState([] as any[]);
    const [form] = Form.useForm();
    const [commandInvoicesEmail, setCommandInvoicesEmail] = useState<Array<CommandInvoiceEmail>>([]);

    const { data, refetch } = useQuery<{ commandInvoice: CommandInvoice }>(
        GET_DATA,
        {
            variables: {
                id: invoiceId,
            },
        },
    );

    const { loading, error, data: dataEmails, refetch: refetchEmails } = useQuery<{ commandInvoicesEmail: CommandInvoiceEmail[] }>(
        GET_DATA_EMAIL,
        {
            variables: {
                id: invoiceId,
            },
        },
    );

    useEffect(() => {
        if (data && data.commandInvoice) {
            form.resetFields();
            const fields = ObjectService.mapFormFieldsObject(data.commandInvoice, {
            });

            setFields(fields);
        }
    }, [data, refetch]);

    useEffect(() => {
        refetchEmails();
        if (dataEmails && dataEmails.commandInvoicesEmail) {
            setCommandInvoicesEmail(dataEmails.commandInvoicesEmail);
        }
    }, [dataEmails, refetchEmails]);

    const [createCommandInvoiceEmail] = useMutation(CREATE_COMMAND_INVOICE_EMAIL, {
        onCompleted: () => {
            notification.success({ message: 'Facture envoyée par email' });
            refetch();
            refetchEmails();
        },
        onError: (err: Error) => {
            setFormLoading(false);
            notification.error({
                message: "Erreur lors de l'enregistrement",
                description: err.message,
            });
        },
    });

    function handleSubmit(values: any) {
        setFormLoading(true);
        let invoiceValues: any = { ...values };
        invoiceValues.commandInvoice = invoiceId;
        invoiceValues.nameInvoice = undefined;
        invoiceValues.sentTo = invoiceValues['client.email'];
        invoiceValues.sentToBis = invoiceValues['client.emailBis'];
        invoiceValues['client.email'] = undefined;
        invoiceValues['client.emailBis'] = undefined;
        createCommandInvoiceEmail({
            
            variables: {
                input: invoiceValues
            },
            
        }).then(refetch, refetchEmails).catch(error => {
            notification.error({
                message: 'Erreur : ' + error.message(),
                description: 'Erreur lors de l’enregistrement.',
                duration: 4.5,
            });
        }).finally(() => setFormLoading(false));
    }

    const columns = [
        {
            title: 'Envoyé à',
            dataIndex: 'sentTo',
            key: 'sentTo'
        },
        {
            title: 'Envoyé à',
            dataIndex: 'sentToBis',
            key: 'sentToBis'
        },
        {
            title: 'Envoyé le',
            dataIndex: 'sentAt',
            key: 'sentAt',
            render: (datetime: string) =>
                datetime && moment(datetime).format('DD/MM/YYYY HH:mm'),
            defaultSortOrder: 'ascend' as 'ascend',
        },
        {
            title: 'Envoyé par',
            key: 'sentBy',
            render: (row: CommandInvoiceEmail) =>
                row.sentBy &&
                `${row.sentBy.firstName} ${row.sentBy.lastName.toUpperCase()}`,
        },
    ];
    return (
        
            <Modal
                className="send-invoice-modal"
                title="Envoyer une facture par email"
                visible={visible}
                onCancel={onClose}
                footer={null}
            >
            <div className="email-list">
                {(loading || error) && <LoaderOverlay loading />}
                <div className="__header">
                    <h2>Historique des emails</h2>
                </div>
                <FilteredTable rowKey="commandInvoice" dataSource={commandInvoicesEmail} columns={columns} />
            </div>
                <CommandInvoiceSendForm
                    onChange={setFields}
                    fields={fields}
                    onSubmit={handleSubmit}
                    loading={formLoading}
                    refetch={refetch}
                />
                
            </Modal>
        

    )

}