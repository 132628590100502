import React, { useState, useEffect } from 'react';
import ArrayService from 'shared/services/ArrayService';
import { Button, Popover } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import SortListItem from 'components/SortListItem';
import './DeliveryZoneList.scss';
import useDebounce from 'shared/hooks/useDebounce';

interface DeliveryZoneListProps {
    zones: DeliveryZone[];
    onZoneChange: (zone: any) => void;
    onAssignClient: (clientId: number, zoneId: Id | null) => void;
    clients: Client[];
}

interface DeliveryZone {
    id: Id;
    name: string;
    color: string;
    defaultClients?: {
        id: Id;
        sortOrder: number;
        client: {
            id: number;
        };
    }[];
}

interface Client {
    id: number;
    firstName: string;
    lastName: string;
}

export default function DeliveryZoneList({
    zones,
    onZoneChange,
    onAssignClient,
    clients,
}: DeliveryZoneListProps) {
    const unassignedClients = clients.filter(
        (client) =>
            zones.findIndex(
                (zone) =>
                    zone.defaultClients &&
                    zone.defaultClients.findIndex(
                        (c) => c.client.id === client.id,
                    ) > -1,
            ) === -1,
    );

    return (
        <div className="delivery-zone-list">
            {unassignedClients.length > 0 && (
                <div className="__zone-wrapper">
                    <div className="__title">Clients à assigner</div>
                    <div className="__clients">
                        {unassignedClients.map((client) => (
                            <ClientCard
                                key={client.id}
                                zones={zones}
                                client={client}
                                onClientClick={onAssignClient}
                            />
                        ))}
                    </div>
                </div>
            )}
            {zones.map((zone) => (
                <DeliveryZoneColumn
                    key={zone.id}
                    zone={zone}
                    zones={zones}
                    clients={clients}
                    onAssignClient={onAssignClient}
                    onZoneChange={onZoneChange}
                />
            ))}
        </div>
    );
}

interface DeliveryZoneColumnProps {
    zone: DeliveryZone;
    zones: DeliveryZone[];
    clients: Client[];
    onAssignClient: (clientId: number, zoneId: Id | null) => void;
    onZoneChange: (zone: any) => void;
}
function DeliveryZoneColumn({
    zone,
    zones,
    clients,
    onAssignClient,
    onZoneChange,
}: DeliveryZoneColumnProps) {
    const [defaultClients, setDefaultClients] = useState(zone.defaultClients);

    useEffect(() => {
        setDefaultClients(zone.defaultClients);
    }, [zone.defaultClients]);

    const dOnZoneChange = useDebounce(onZoneChange, 1000);

    function handleMoveClient(fromIndex: number, toIndex: number) {
        if (!defaultClients) {
            return;
        }
        let newClients = defaultClients.sort((c1, c2) =>
            c1.sortOrder > c2.sortOrder ? 1 : -1,
        );
        const client = newClients.find((c) => c.sortOrder === fromIndex);
        if (client) {
            newClients.splice(fromIndex, 1);
            newClients.splice(toIndex, 0, client);
            newClients = newClients
                .filter(ArrayService.filterUniqueBy((c) => c.client.id))
                .map((c, index) => ({
                    ...c,
                    sortOrder: index,
                }));

            setDefaultClients(newClients);

            const newClientsInput = newClients.map((c) => ({
                sortOrder: c.sortOrder,
                client: c.client.id,
            }));
            dOnZoneChange({ id: zone.id, defaultClients: newClientsInput });
        }
    }

    if (!defaultClients) {
        return null;
    }
    return (
        <div key={zone.id} className="__zone-wrapper">
            <div className="__title">{zone.name}</div>
            <div className="__clients">
                {defaultClients
                    .sort(ArrayService.cmpBy('sortOrder'))
                    .map((c) => {
                        const client = clients.find(
                            (client) => client.id === c.client.id,
                        );
                        return (
                            client && (
                                <ClientCard
                                    key={client.id}
                                    selectedZone={{ ...zone, defaultClients }}
                                    zones={zones}
                                    client={client}
                                    onPositionChange={handleMoveClient}
                                    onClientClick={onAssignClient}
                                />
                            )
                        );
                    })}
            </div>
        </div>
    );
}

interface ClientCardProps {
    selectedZone?: DeliveryZone;
    zones: DeliveryZone[];
    client: Client;
    onPositionChange?: (fromIndex: number, toIndex: number) => void;
    onClientClick: (clientId: number, zoneId: Id | null) => void;
}
function ClientCard({
    client,
    selectedZone,
    zones,
    onPositionChange,
    onClientClick,
}: ClientCardProps) {
    const popoverContent = (
        <>
            <Button
                style={{ background: '#aaaaaa' }}
                icon={!selectedZone && <CheckOutlined />}
                onClick={
                    onClientClick
                        ? () => onClientClick(client.id, null)
                        : undefined
                }
            >
                Aucune
            </Button>
            {zones.map((zone) => (
                <Button
                    key={zone.id}
                    style={{ background: zone.color }}
                    icon={
                        selectedZone &&
                        zone.id === selectedZone.id && <CheckOutlined />
                    }
                    onClick={
                        onClientClick
                            ? () => onClientClick(client.id, zone.id)
                            : undefined
                    }
                >
                    {zone.name}
                </Button>
            ))}
        </>
    );

    const content = (
        <>
            <div className="__name">{`${
                client.firstName
            } ${client.lastName.toUpperCase()}`}</div>
            <Popover
                content={popoverContent}
                trigger="click"
                placement="right"
                overlayClassName="delivery-zone-list__client-card__action-menu"
            >
                <div
                    className="__action"
                    style={{
                        backgroundColor: selectedZone?.color || '#d0d0d0',
                    }}
                ></div>
            </Popover>
        </>
    );

    if (!selectedZone || !onPositionChange) {
        return (
            <div className="__client-card">
                <div className="__content">{content}</div>
            </div>
        );
    }

    const clientPosition =
        selectedZone?.defaultClients?.find((c) => c.client.id === client.id)
            ?.sortOrder || 0;

    return (
        <SortListItem
            id={client.id}
            listId={selectedZone.id}
            index={clientPosition}
            onMove={onPositionChange}
            className="__client-card"
        >
            {content}
        </SortListItem>
    );
}
